import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import SystemUsers from './system-users'
import SystemReboot from './system-reboot'
import { useTranslation } from 'react-i18next'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import SystemDatetimes from './system-datetime'
import SystemUpdate from './update/system-update'
import SystemRestore from './system-restore'
import SystemInformation from './system-information'
import SystemBackupRestore from './settings/system-backup-restore'
import SystemTR069ConfigPage from './system-tr069-config'
import SystemAclAdmin from './system-acl-admin'
import SystemChangeToFirmwareVersion from './system-change-to-firmware-version'
import SystemLeds from './system-leds'
import SystemState from './system-context'
import TabsMobile from '../../common/components/tabs/tabs-mobile';
import SystemAutomaticMaintenance from './automatic-maintenance/system-automatic-maintenance'
/*IMPORTS_TAG*/

let SystemPage = () => {
    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: t('menu.USER_AND_PASSWORD'), route: '/system/users' },
        { label: t('menu.ACL_ADMIN'), route: '/system/acl_admin' },
        { label: t('menu.TR069_CONFIG'), route: '/system/tr069-config' },
        { label: t('menu.LEDS'), route: '/system/leds' },
        { label: t('menu.REBOOT'), route: '/system/reboot' },
        { label: t('menu.FACTORY_RESET'), route: '/system/restore' },
        { label: t('menu.BACKUP_RESTORE'), route: '/system/backup_restore' },
        { label: t('menu.CHANGE_TO_FIRMWARE_VERSION'), route: '/system/change_to_firmware_version' },
        { label: t('menu.UPDATE_FIRMWARE'), route: '/system/update' },
        { label: t('menu.DATETIME'), route: '/system/datetime' },
        { label: t('menu.AUTOMATIC_MAINTENANCE'), route: '/system/automatic-maintenance' },
        { label: t('menu.INFORMATION'), route: '/system/information' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.SYSTEM'))
        header.backRoute.set('/system/users')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('system') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <SystemState>
            <TabsMobile
                data={tabs}
                route={history.location.pathname}
                activateFn={changeRoute}
            ></TabsMobile>

            <Switch>
                <Route path='/system/users' render={(props) => {
                    return <SystemUsers {...props} ></SystemUsers>
                }}></Route>

                <Route path='/system/reboot' render={(props) => {
                    return <SystemReboot {...props} ></SystemReboot>
                }}></Route>

                <Route path='/system/datetime' render={(props) => {
                    return <SystemDatetimes {...props} ></SystemDatetimes>
                }}></Route>

                <Route path='/system/update' render={(props) => {
                    return <SystemUpdate {...props} ></SystemUpdate>
                }}></Route>

                <Route path='/system/restore' render={(props) => {
                    return <SystemRestore {...props} ></SystemRestore>
                }}></Route>

                <Route path='/system/backup_restore' render={(props) => {
                    return <SystemBackupRestore {...props} ></SystemBackupRestore>
                }}></Route>

                <Route path='/system/tr069-config' render={(props) => {
                    return <SystemTR069ConfigPage {...props} ></SystemTR069ConfigPage>
                }}></Route>

                <Route path='/system/information' render={(props) => {
                    return <SystemInformation {...props} ></SystemInformation>
                }}></Route>

                <Route path='/system/acl_admin' render={(props) => {
                    return <SystemAclAdmin {...props} ></SystemAclAdmin>
                }}></Route>

                <Route path='/system/change_to_firmware_version' render={(props) => {
                    return <SystemChangeToFirmwareVersion {...props} ></SystemChangeToFirmwareVersion>
                }}></Route>

                <Route path='/system/leds' render={(props) => {
                    return <SystemLeds {...props} ></SystemLeds>
                }}></Route>

                <Route path='/system/automatic-maintenance' render={(props) => {
                    return <SystemAutomaticMaintenance {...props} ></SystemAutomaticMaintenance>
                }}></Route>

                {/*ROUTES_TAG*/}
            </Switch>
        </SystemState>
    )
}

export default SystemPage