import React, { useState, useEffect, useContext } from 'react'
import { fetchAclCapability, saveAclCapability, fetchAclConfigList, saveAclConfig, deleteAclConfigById } from '../../../resources/acl-ipv6-admin';
import { BackendContext } from '../../../../backend/backend';
import { fetchServiceSystem } from '../../../resources/system';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';
import List from '../../../../components/list/list';
import RemoveIcon from '../../../../components/icons/remove';
import InfoIcon from '../../../../components/icons/info-icon';
import Modal, { ModalTypes } from '../../../../components/modal/modal';
import common from '../../../../components/form/validators/common';
import Input from '../../../../components/input/input';
import extraValidators from '../../../common/validators';
import Select from '../../../../components/select/select';
import Pager from '../../../../components/pager/pager';
import { createLimitedTableCell } from '../../../utils/common';

import './system-acl-ipv6.css'

export default function SystemAclIpv6({ isWizard, setWizardAclAdmin, isFormSegment, formSegment }) {
    
    const MAX_ACL_V6_RULES = 8
    const IPV6_MAX_STRING_SIZE = 20

    const emptyAdminConfigData = {
        enable: false,
        interfaceID: "lan",
        ip6_start_addr: "",
        ip6_prefix_length: 64,
        service_any: false,
        service_ftp: false,
        service_http: false,
        service_https: false,
        service_ping: false,
        service_ssh: false,
        service_telnet: false,
        service_tftp: false,
    }

    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [success, setSuccess] = useState(false)
    let [aclCapability, setAclCapability] = useState(null)
    let [aclCapabilityChanged, setAclCapabilityChanged] = useState(false)
    let [aclConfigList, setAclConfigList] = useState([])
    let [aclConfigItem, setAclConfigItem] = useState({ ...emptyAdminConfigData })
    let [deleteAclAdminConfigItemData, setDeleteAclAdminConfigItemData] = useState(null)
    let [system, setSystem] = useState(true)
    const [pagination, setPagination] = useState(null)

    let [showInfoModal, setShowInfoModal] = useState(false)
    let [servicesAndPorts, setServicesAndPorts] = useState([])
    let [servicesInfo, setServicesInfo] = useState([])

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {
        setPagination({ pageNumber: 1, totalElements: 0, pageSize: 5 })
        fetchAclCapability(backend, setAclCapability)
        fetchAclConfigList(backend, setAclConfigList)
        fetchServiceSystem(backend, setSystem)
        setDeleteAclAdminConfigItemData({ showDelete: false, id: null })

        // eslint-disable-next-line
    }, [])

    let save = async () => {

        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        let ok = await saveAclCapability(backend, aclCapability, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
        setAclCapabilityChanged(false)
    }

    let saveAclConf = async () => {
        let filtered = aclConfigList.filter(acli => acli.id !== aclConfigItem.id && acli.ip6_start_addr === aclConfigItem.ip6_start_addr && acli.interfaceID === aclConfigItem.interfaceID)
        if (filtered.length > 0) {
            setErrorMessage(t('system.acl_admin.message.error.CONFLIT_WITH_OTHER_RULE'))
            setError(true)
            return
        }

        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        let ok = await saveAclConfig(backend, aclConfigItem, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        setAclConfigItem({ ...emptyAdminConfigData })
        setAclConfigList([])
        fetchAclConfigList(backend, setAclConfigList)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const getInterfaceOptions = () => {
        return [{ value: 'lan', text: 'LAN' }, { value: 'wan', text: 'WAN' }]
    }

    const getColumnsAclConfig = () => {

        let columns = [
            { header: t('common.label.IPV6'), align: 'center', size: '200px' },
            { header: <div style={{ whiteSpace: "normal" }}>{t('ipv6.radvd.label.PREFIX_SIZE')}</div>, align: 'center', size: '150px' },
            { header: t('common.label.INTERFACE'), align: 'center', size: '150px' },
            { header: t('system.acl_admin.label.SERVICES'), align: 'center', size: '150px' },
        ]

        return !aclCapabilityChanged ? [...columns, { header: t('common.label.ACTIONS'), align: 'center' }] : columns
    }

    const DeleteAdminConfigItem = (id) => {

        return <Modal show='true'
            type={ModalTypes.CONFIRM_WARNING}

            onDismissClick={conf => {
                if (conf) {
                    deleteAclAdminConfigItem(id.id)
                }
                setDeleteAclAdminConfigItemData({ showDelete: false })
            }}
            content={
                <div style={{ width: '420px' }}>
                    <span style={{ display: 'block' }}><Translator path="common.message.warning.PERMANENT_DELETE_WARNING"></Translator>
                        <br></br>
                    </span>
                    <b><Translator path="common.message.warning.CONFIRM_EXCLUSION"></Translator></b>
                </div>
            }
            dismissText={t('common.label.CANCEL')}
            confirmText={t('common.label.CONFIRM')}
        ></Modal>
    }

    const deleteAclAdminConfigItem = id => {
        if (aclConfigItem.id === id) {
            setAclConfigItem({ ...emptyAdminConfigData })
        }
        setAclConfigList([])
        deleteAclConfigById(backend, id, setAclConfigList)
    }

    const showAclAdminConfigItem = id => {
        setDeleteAclAdminConfigItemData({ showDelete: true, id: id })
    }

    const getAclConfig = () => {

        if (aclConfigList.length !== pagination.totalElements) {
            setPagination({ ...pagination, totalElements: aclConfigList.length })
        }

        let wanLines = [];

        let begining = (pagination.pageNumber - 1) * (pagination.pageSize)
        let end = begining + pagination.pageSize
        if (end > pagination.totalElements) {
            end = pagination.totalElements
        }
        for (let i = begining; i < end; i++) {
            if (aclConfigList[i]) {
                let services = []
                if (aclConfigList[i].service_any) {
                    services.push([t('system.acl_admin.label.ANY')])
                }
                else {
                    aclCapability.available_services.filter(services => services !== "").forEach(service => {
                        aclConfigList[i][`service_${service}`] && services.push([service])
                    });
                }

                let fields = [
                    <div>{createLimitedTableCell(`${aclConfigList[i].ip6_start_addr}`, IPV6_MAX_STRING_SIZE)}</div>,
                    aclConfigList[i].ip6_prefix_length,
                    aclConfigList[i].interfaceID,
                    <span className="clickable" onClick={e => {
                        setServicesInfo(
                            <div>
                                {t('common.label.INTERFACE')}: {aclConfigList[i].interfaceID}
                                <br />
                                {'Endereço:'}: {aclConfigList[i].ip6_start_addr}
                                <br />
                                {'Prefixo'}: {aclConfigList[i].ip6_prefix_length}
                            </div>
                        )
                        setServicesAndPorts(services)
                        setShowInfoModal(true)
                    }}><InfoIcon size='20px' /></span>
                ]

                wanLines.push(
                    !aclCapabilityChanged ? [...fields, <>
                        <span title={t('common.label.DELETE')} className="firewall-acl-admin-delete-icon clickable" onClick={e => showAclAdminConfigItem(aclConfigList[i].id)}>
                            <RemoveIcon />
                        </span>
                    </>] : fields
                )
            }
        }

        return wanLines

    }

    const systemAclAdminForm = () => {
        return <React.Fragment>
            <Checkbox id='system-acl-ipv6'
                name='ipv6_acl_capability'
                label={<Translator path="system.acl_admin.label.ENABLE_ACL_ADMIN"></Translator>}
                value={aclCapability.ipv6_acl_capability}
                toggleFn={(e) => {
                    setAclCapabilityChanged(true)
                    aclCapability.ipv6_acl_capability = !aclCapability.ipv6_acl_capability;
                    setAclCapability({ ...aclCapability })
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    const systemAclAdminConfigItemForm = () => {

        return <React.Fragment>
            <Input id='system-acl-ipv6-config-ip-start-addr'
                name='ip6_start_addr'
                type="text"
                label={<Translator path="common.label.IPV6" required="true"></Translator>}
                value={aclConfigItem.ip6_start_addr}
                onChange={(e) => {
                    aclConfigItem.ip6_start_addr = e.target.value
                    setAclConfigItem({ ...aclConfigItem })
                }}
                validators={[
                    common.required,
                    common.nonASCII,
                    extraValidators.validateIPv6,
                    extraValidators.validateIfNotLocalhost
                ]}
            ></Input>

            <Input id='system-acl-ipv6-config-ip6-prefix-length'
                name='ip6_prefix_length'
                type="text"
                label={<Translator path="ipv6.radvd.label.PREFIX_SIZE" required="true"></Translator>}
                value={aclConfigItem.ip6_prefix_length}
                onChange={(e) => {
                    setAclConfigItem({ ...aclConfigItem, ip6_prefix_length: Number(e.target.value) })
                }}
                validators={[common.required, common.isNumber, { fn: extraValidators.value, params: { min: 48, max: 68 } }]}
            ></Input>


            <Select
                id='system-acl-ipv6-config-interface-id'
                name='interfaceID'
                label={<Translator path="common.label.INTERFACE"></Translator>}
                options={getInterfaceOptions()}
                value={aclConfigItem.interfaceID}
                onChange={(e) => {
                    aclConfigItem.interfaceID = e.target.value

                    aclConfigItem.service_telnet = false;
                    aclConfigItem.service_tftp = false;
                    aclConfigItem.service_ftp = false;
                    aclConfigItem.service_http = false;
                    aclConfigItem.service_https = false;
                    aclConfigItem.service_ssh = false;

                    if (aclConfigItem.interfaceID === 'lan')
                        aclConfigItem.service_ping = true;
                    else {
                        aclConfigItem.service_ping = false
                    }
                    setAclConfigItem({ ...aclConfigItem })

                }}

            ></Select>

            {!aclConfigItem.service_any ?
                <div
                    style={{
                        display: 'grid',
                        width: '100%',
                        gridTemplateColumns: '20% 40% 40% 0%',
                        gridTemplateRows: 'auto',
                    }}
                >
                    {aclCapability.available_services.filter(services => services !== "").map((avs, key) =>
                        <div
                            key={`system-acl-ipv6-config-${avs}`}
                            style={{
                                gridColumnStart: parseInt(key % 2) + 2,
                                gridRowStart: parseInt(key / 2) + 1,
                            }}
                        >
                            <div style={{ width: '200px' }}>
                                <div style={{ display: 'inline-block', width: '50px' }}>
                                    <Checkbox key={avs} id={`system-acl-ipv6-config-${avs}-enable`}
                                        name='avs'
                                        label={<Translator path={`system.acl_admin.label.${avs.toUpperCase()}`}></Translator>}
                                        value={aclConfigItem[`service_${avs}`]}
                                        toggleFn={(e) => {
                                            if ((avs === 'any' || avs === 'tftp' || avs === 'ping'))
                                                aclConfigItem[`service_${avs}`] = !aclConfigItem[`service_${avs}`];
                                            else
                                                aclConfigItem[`service_${avs}`] = !aclConfigItem[`service_${avs}`]

                                            setAclConfigItem({ ...aclConfigItem })
                                        }}
                                        disabled={(aclConfigItem.interfaceID === 'lan' && avs === 'ping') || (aclConfigItem.interfaceID !== 'lan' && avs === 'any')}
                                    ></Checkbox>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                :
                <Checkbox id={'system-acl-ipv6-config-any'}
                    name='avs'
                    label={<Translator path={`system.acl_admin.label.ANY`}></Translator>}
                    value={aclConfigItem[`service_any`]}
                    toggleFn={(e) => {
                        aclConfigItem[`service_any`] = !aclConfigItem[`service_any`];
                        aclConfigItem.service_telnet = false;
                        aclConfigItem.service_tftp = false;
                        aclConfigItem.service_ftp = false;
                        aclConfigItem.service_http = false;
                        aclConfigItem.service_https = false;
                        aclConfigItem.service_ssh = false;

                        if (!aclConfigItem[`service_any`] && aclConfigItem.interfaceID === 'lan')
                            aclConfigItem.service_ping = true;
                        else
                            aclConfigItem.service_ping = false;

                        setAclConfigItem({ ...aclConfigItem })

                    }}
                    validators={[]}
                ></Checkbox>
            }
        </React.Fragment>
    }

    const changePage = page => {
        setPagination({ ...pagination, pageNumber: page })
    }

    const hasIPv6Requiremnts = () => {
        if (isWizard || system === true) {
            return true
        }

        if (system) {
            if (system.ipv6) {
                if (system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }

    return !aclCapability ? <Loading show={true}></Loading> :
        <div id='system-acl-ipv6-page' className='container scroll-area'>
            {deleteAclAdminConfigItemData.showDelete && <DeleteAdminConfigItem id={deleteAclAdminConfigItemData.id}></DeleteAdminConfigItem>}
            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismiss}
                errorText={`${t('system.acl_admin.message.error.ERROR_ON_SERVER')} ${errorMessage ? errorMessage : ''}`}

            ></DefaultStatusModals>
            {
                hasIPv6Requiremnts() ? null :
                    <div>
                        <p className='system-acl-ipv6-info'>
                            {t(`system.acl_admin.message.IPV6_DISABLED`)}
                            <br />
                            {t(`common.message.warning.IPV6_ENABLE`)}
                            <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                        </p>
                    </div>
            }

            <Modal show={showInfoModal}
                title={t('system.acl_admin.title.SERVICE_LIST')}
                type={ModalTypes.INFO}
                onDismissClick={() => setShowInfoModal(false)}
                dismissText={t('common.label.BACK')}
                content={
                    <div >
                        <div>{servicesInfo}</div>
                        <List
                            width='400px'
                            lines={servicesAndPorts}
                            columns={[
                                { header: t('system.acl_admin.label.SERVICES'), align: 'center' }
                                // { header: t('advanced.bridging.title.PORT'), align: 'center' }
                            ]}
                        ></List>
                    </div>
                }
            ></Modal>

            <React.Fragment>
                <div className='subtitle'> <Translator path="system.acl_admin.title.ACL_CONFIG_IPV6"></Translator> </div>
                <div className="card mt2">
                    <div className='subtitle'> <Translator path="system.acl_admin.title.ACL_ADMIN_CAPABILITY"></Translator> </div>
                    <Form id='system-acl-ipv6-form'
                        onSubmit={save}
                        buttonId='button-save-acl-admin-capability'
                        disableButton={!hasIPv6Requiremnts()}
                    >
                        {systemAclAdminForm()}
                    </Form>
                </div>

            </React.Fragment>

            <React.Fragment>
                {
                    <div className="card mt2">
                        <div className='subtitle'> <Translator path='system.acl_admin.title.ACL_CONFIG_CREATE'></Translator> </div>
                        <Form id='system-acl-ipv6-form'
                            onSubmit={saveAclConf}
                            buttonId='button-save-acl-config-admin'
                            disableButton={!aclCapability.available_services.filter(services => services !== "").some(avs => aclConfigItem[`service_${avs}`]) || 
                            !system?.ipv6?.enabled ||
                            aclConfigList.length >= MAX_ACL_V6_RULES
                        }
                        >
                            {systemAclAdminConfigItemForm()}
                            {
                               aclConfigList.length >= MAX_ACL_V6_RULES && <strong><Translator path="system.acl_admin.message.error.MAX_RULES_NUM"></Translator></strong>
                            }
                        </Form>
                    </div>
                }
            </React.Fragment>

            <React.Fragment>
                <div className="system-acl-ipv6-list">
                    <div className='subtitle'> <Translator path="system.acl_admin.title.ACL_CONFIG_LIST"></Translator> </div>
                    <div>
                        {getAclConfig().length > 0 ?
                            <div>
                                <dir className="system-acl-ipv6-list-wrapper">
                                    <List
                                        width='750px'
                                        lines={getAclConfig()}
                                        columns={getColumnsAclConfig()}
                                    ></List>
                                </dir>
                                <Pager
                                    pageNumber={pagination.pageNumber}
                                    totalElements={pagination.totalElements}
                                    pageSize={pagination.pageSize}
                                    pageChangeFn={changePage}>
                                </Pager>
                            </div>
                            :
                            <div id='system-acl-ipv6-no-rules'>
                                <div className='info-card noelements-card'>
                                    <Translator path="system.acl_admin.info.NO_RULES" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>

        </div>
}