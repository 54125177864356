import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BackendContext } from '../../../backend/backend'
import { fetchInterfaceLan } from '../../resources/lan';
import { fetchWanList } from '../../resources/wan';
import './dashboard.css'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import CardButton from '../../../components/cardbutton/cardbutton'
import CogIcon from '../../../components/icons/cog'
import DeviceIcon from '../../../components/icons/device'
import WifiIcon from '../../../components/icons/wifi-icon'
import MeshIcon from '../../../components/icons/mesh-icon'
import Loading from '../../../components/loading/loading'
import { useHistory } from 'react-router-dom'
import InternetWidget from '../../local-components/internet-widget/internet-widget'
import { fetchStatus } from '../../resources/status';
import DashboardDetails from './dashboard-details'
import { fetchDevice } from '../../resources/device'
import { fetchWirelessList, totalWirelessConnections } from '../../resources/wireless'
import { fetchRadioList } from '../../resources/radio'
import { GlobalsContext } from '../../globals-context'
import { fetchPONStats } from '../../resources/pon-stats';
import { fetchEasyMesh } from '../../resources/easy-mesh'
import RadioIcon from '../../../components/icons/radio'
import { fetchInterfaceList } from '../../resources/interface';
import { fetchConnectedDevicesList } from '../../resources/connected-devices';
import { fetchPONStatus } from '../../resources/pon-status';

export default function DashboardPage() {
    let history = useHistory()
    const header = useContext(MainHeaderContext)

    const [lan, setLan] = useState(null)
    const [wanList, setWanList] = useState(null)
    const [status, setStatus] = useState(null)
    const [device, setDevice] = useState(null)
    const [wireless, setWireless] = useState(null)
    const [deviceID, setDeviceID] = useState(null)
    const [radios, setRadios] = useState(null)
    const [ponStats, setPONStats] = useState(null)
    const [ponStatus, setPONStatus] = useState(null)
    const [connectedDevices, setConnectedDevices] = useState(null)

    const backend = useContext(BackendContext)
    let globals = useContext(GlobalsContext)
    const [EasyMesh, setEasyMesh] = useState(null)
    const [interfaceList, setInterfaceList] = useState(null)
    const { t } = useTranslation();

    useEffect(() => {

        if (lan) {
            let id = lan.mac.replace(/:/g, '').toLowerCase()
            setDeviceID(id)
            globals.setItem('deviceid', id)
        }

        // eslint-disable-next-line
    }, [lan])

    useEffect(() => {
        header.title.set(t('menu.DASHBOARD'))
        header.backRoute.set('/')
        header.hidden.set(false)
        header.extended.set(true)
        header.extendedContent.set(getInternetWidget())
        window.onpopstate = () => { }

        return function cleanup() {
            header.hidden.set(false)
            header.extended.set(false)
        };

        // eslint-disable-next-line
    }, [status, ponStats])

    useEffect(() => {
        (async () => {
            await fetchPONStatus(backend, setPONStatus)
            await fetchStatus(backend, setStatus)
            await fetchRadioList(backend, setRadios)
            await fetchWirelessList(backend, setWireless)
            await fetchDevice(backend, setDevice)
            await fetchInterfaceLan(backend, setLan)
            await fetchWanList(backend, setWanList)
            await fetchEasyMesh(backend, setEasyMesh)
            await fetchInterfaceList(backend, setInterfaceList)
            await fetchConnectedDevicesList(backend, setConnectedDevices)
        })();

        let intervalId = setInterval(async () => {
            fetchInterfaceList(backend, setInterfaceList)
            fetchWanList(backend, setWanList)
            fetchPONStatus(backend, setPONStatus)
            fetchPONStats(backend, setPONStats)
        }, 2000);

        return function cleanup() {
            clearInterval(intervalId)
        };

    }, [backend])

    const getInternetWidget = () => {
        return !!status && !!ponStatus &&
            <InternetWidget
                ponStatus={ponStatus}
                status={status}
                download={ponStats?.throughput.rx ? (ponStats.throughput.rx * 8 / 1000000).toFixed(2) : 0} // Informations received in bytes/s. Converting to Mbit/s
                upload={ponStats?.throughput.tx ? (ponStats.throughput.tx * 8 / 1000000).toFixed(2) : 0} // Informations received in bytes/s. Converting to Mbit/s
                tx={ponStatus?.tx_power ? ponStatus?.tx_power.toFixed(2) : 0}
                rx={ponStatus?.rx_power ? ponStatus?.rx_power.toFixed(2) : 0}
            >
            </InternetWidget>
    }

    const countConnectedDevices = (connectedDevices, array) => {

        for (let i = 0; i < array?.length; i++) {

            if (array[i]?.is_online) {
                connectedDevices++
                connectedDevices = countConnectedDevices(connectedDevices, array[i]?.neighbors)
            }
        }
        return connectedDevices;
    }

    const getMeshDevices = () => {
        let connectedDevices = '0'

        connectedDevices = countConnectedDevices(connectedDevices, EasyMesh?.neighbors)

        return connectedDevices
    }

    return (
        <div id='dashboard-page'>

            <div id='dashboard-content'>
                <div className='dashboard-buttons'>
                    <CardButton
                        Icon={WifiIcon}
                        iconSize='32px'
                        label={t('dashboard.label.WLAN_INTERFACES')}
                        value={
                            !!wireless && !!radios && !!EasyMesh ? totalWirelessConnections(wireless, radios, EasyMesh) :
                                <Loading mini={true} show={true}></Loading>
                        }
                        onClick={() => history.push('/network/wifi')}
                        Id={'card-networks-list'}
                    ></CardButton>
                    <CardButton
                        Icon={RadioIcon}
                        iconSize='32px'
                        label={t('dashboard.label.RADIO_CONFIG')}
                        value={radios?.length > 1 ? '2.4 GHz / 5 GHz' : '2.4 GHz'}
                        onClick={() => history.push('/network/wifi/radio')}
                        Id={'card-networks-channels'}
                    ></CardButton>
                    <CardButton
                        Icon={DeviceIcon}
                        iconSize='32px'
                        label={t('dashboard.label.CONNECTED_DEVICES')}
                        value={
                            connectedDevices ? connectedDevices.length : <Loading mini={true} show={true}></Loading>
                        }
                        onClick={() => history.push('/parental-control/connected-devices')}
                        Id={'card-connected-devices'}
                    ></CardButton>
                    <CardButton
                        Icon={CogIcon}
                        iconSize='32px'
                        label={t('dashboard.label.WAN_INTERFACES')}
                        value={!!wanList ? wanList.length : '0'}
                        onClick={() => history.push('/network/wan')}
                        Id={'card-networks-vlans'}
                    ></CardButton>
                    <CardButton
                        Icon={MeshIcon}
                        iconSize='32px'
                        label={t('dashboard.label.INMESH_CONNECTION')}
                        value={getMeshDevices()}
                        onClick={() => history.push('/network/mesh/settings')}
                        Id={'card-tr069'}
                    ></CardButton>
                </div>
            </div>

            <DashboardDetails
                ponStatus={ponStatus}
                device={device}
                wireless={wireless}
                lan={lan}
                deviceID={deviceID}
                wan={wanList}
                wanInterface={interfaceList}
                radios={radios}
            ></DashboardDetails>

        </div>
    )
}