import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import './network-wan.css'
import { BackendContext } from '../../../../backend/backend'
import { GlobalsContext } from '../../../globals-context';
import { useHistory } from 'react-router-dom';
import { fetchNetworkWan, saveNetworkWan, WANServiceTypeOptions, WANServiceTypeBridgedOptions, fetchNetworkWanList } from '../../../resources/network-wan';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input';
import Select from '../../../../components/select/select';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import extraValidators from '../../../common/validators';
import Button from '../../../../components/button/button';
import { fetchInterface, saveInterface } from '../../../resources/interface';
import { fetchPppoe, savePpppoe } from '../../../resources/pppoe';
import { fetchServiceType, saveServiceType } from '../../../resources/service-type';
import { LanWlanInterfaceName, Wlan0ID, Wlan1ID, fetchWanLan, fetchWanLanList, saveWanLan } from '../../../resources/wan-lan-list';
import { fetchServiceSystem } from '../../../resources/system';
// import CollapseBox from '../../../../components/collapsebox/collapse-box';
import Translator from '../../../common/components/translator/translator'
import { useTranslation } from 'react-i18next';
import Ip from 'ip'
import { fetchLan } from '../../../resources/lan';
import { fetchPONStatus } from '../../../resources/pon-status';
import { fetchRadioList, frequency } from '../../../resources/radio';
import { fetchWirelessList } from '../../../resources/wireless'
import { Protocol, Mode, ipv6AddressMode } from './network-wan-common'
import { MeshContext } from '../mesh/mesh-context';

const LanCleanId = {
    lan1: 'LAN 1',
    lan2: 'LAN 2',
    lan3: 'LAN 3',
    lan4: 'LAN 4'
}

export default function NetworkWan({ isWizard, setSaved, isFormSegment, formSegment }) {
    let [networkWan, setNetworkWan] = useState(null)
    let [networkWanList, setNetworkWanList] = useState(null)
    let [interfaceWan, setInterfaceWan] = useState(null)
    let [pppoeConfig, setPppoeConfig] = useState(null)
    let [serviceType, setServiceType] = useState(null)
    let [wanLan, setWanLan] = useState(null)
    let [wanLanList, setWanLanList] = useState([])
    let [lan, setLan] = useState(null)
    let [system, setSystem] = useState(null)
    let [ponStatus, setPONStatus] = useState(null)
    let [networkWanFetched, setNetworkWanFetched] = useState(false)
    const [radioList, setRadioList] = useState(null)
    const [wirelessList, setWirelessList] = useState(null)
    const [easyMesh, setEasyMesh] = useState(null)

    const [originalNetworkWanMode, setOriginalNetworkWanMode] = useState(Mode.NONE)
    const [originalNetworkWanProtocol, setOriginalNetworkWanProtocol] = useState(Mode.NONE)

    const [errorMessage, setErrorMessage] = useState("")

    const { t } = useTranslation()

    const params = useParams()

    const history = useHistory()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [enableMultcastVlan, setEnableMultcastVlan] = useState(false)

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const meshContext = useContext(MeshContext)

    useEffect(() => {
        fetchNetworkWanList(backend, setNetworkWanList)
        fetchServiceSystem(backend, setSystem)
        fetchNetworkWan(params.id === 'new' ? null : params.id, backend, setNetworkWan)
        fetchLan(backend, setLan)
        fetchPONStatus(backend, setPONStatus)
        fetchRadioList(backend, setRadioList)
        fetchWirelessList(backend, setWirelessList)

        if (!meshContext.easyMesh) {
            meshContext.retrieveEasyMesh()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setEasyMesh(meshContext.easyMesh)
        // eslint-disable-next-line
    }, [meshContext.easyMesh])

    const shouldDisable = pm => {
        return wanLanList?.some(wlli => wlli.id !== networkWan.id && wlli.lan_list.some(wlliL => wlliL.lan_id === pm.lan_id && wlliL.enabled))
    }

    const isVapEnabled = pm => {
        if (pm.lan_id === LanWlanInterfaceName.lan1 || pm.lan_id === LanWlanInterfaceName.lan2 ||
            pm.lan_id === LanWlanInterfaceName.lan3 || pm.lan_id === LanWlanInterfaceName.lan4 ||
            pm.lan_id === LanWlanInterfaceName.wlan0 || pm.lan_id === LanWlanInterfaceName.wlan1) {
            return true
        }

        if (radioList && wirelessList) {
            let isEnabled = false
            let wlanVAP = null

            let radio = radioList.filter(radio => radio.frequency === frequency._5GHz)
            if (radio ? radio[0].enabled : false) {
                wlanVAP = Wlan0ID.filter(wlan => { return wlan.value === pm.lan_id })
                if (wlanVAP.length === 1) {
                    isEnabled = wirelessList ? wirelessList.some(ap => {
                        if (easyMesh && easyMesh.enabled) {
                            return (ap.id === wlanVAP[0].id) && (ap.id !== 'wlan0-vap0')
                        }
                        else {
                            return (ap.id === wlanVAP[0].id)
                        }
                    }) : null
                }
            }

            radio = radioList.filter(radio => radio.frequency === frequency._2_4GHz)
            if (radio ? radio[0].enabled : false) {
                wlanVAP = Wlan1ID.filter(wlan => { return wlan.value === pm.lan_id })
                if (wlanVAP.length === 1) {
                    isEnabled = wirelessList ? wirelessList.some(ap => {
                        if (easyMesh && easyMesh.enabled) {
                            return (ap.id === wlanVAP[0].id) && (ap.id !== 'wlan1-vap0')
                        }
                        else {
                            return (ap.id === wlanVAP[0].id)
                        }
                    }) : null
                }

                return isEnabled
            }
        }
        return false
    }

    const isRootEnabled = pm => {
        if (pm.lan_id !== LanWlanInterfaceName.wlan0 && pm.lan_id !== LanWlanInterfaceName.wlan1) {
            return true
        }

        if (radioList) {
            let radio = null
            let isEnabled = false

            if (pm.lan_id === LanWlanInterfaceName.wlan0) {
                radio = radioList.filter(radio => radio.frequency === frequency._5GHz)
                isEnabled = radio ? radio[0].enabled : false

                return isEnabled
            }

            if (pm.lan_id === LanWlanInterfaceName.wlan1) {
                radio = radioList.filter(radio => radio.frequency === frequency._2_4GHz)
                isEnabled = radio ? radio[0].enabled : false

                return isEnabled
            }
        }

        return false
    }

    useEffect(() => {

        fetchWanLanList(backend, setWanLanList)
        if (networkWan?.id || params.id === 'new') {
            if (!interfaceWan) {
                fetchInterface(params.id === 'new' ? 'new' : networkWan.id, backend, setInterfaceWan, true)
            }
            if (!pppoeConfig) {
                fetchPppoe(params.id === 'new' ? 'new' : networkWan.id, backend, setPppoeConfig)
            }
            if (!serviceType) {
                fetchServiceType(params.id === 'new' ? 'new' : networkWan.id, backend, setServiceType)
            }
            if (!wanLan) {
                fetchWanLan(params.id === 'new' ? 'new' : networkWan.id, backend, setWanLan, globals.containsFourLanPorts())
            }
        }

        if (params.id !== 'new' && networkWan?.multicast_vlanID > 0)
            setEnableMultcastVlan(true)

        if (networkWan?.id) {
            if (originalNetworkWanMode === Mode.NONE)
                setOriginalNetworkWanMode(networkWan.mode)

            if (originalNetworkWanProtocol === Mode.NONE)
                setOriginalNetworkWanProtocol(networkWan.protocol)
        }

        setNetworkWanFetched(true)
        // eslint-disable-next-line
    }, [networkWan])

    useEffect(() => {
        if (ponStatus?.current_mode === 'gpon')
            setNetworkWan({ ...networkWan, vlan_enable: true })
        // eslint-disable-next-line
    }, [ponStatus, networkWanFetched])

    const goBackToList = () => {
        history.push(`/network/wan`)
    }

    const getConnectionValue = (serviceType) => {
        if (!serviceType) {
            return 1
        }
        let result = WANServiceTypeOptions.filter(ct => {
            return ct.serviceType.service_other_video === serviceType?.service_other_video &&
                ct.serviceType.service_tr069 === serviceType?.service_tr069 &&
                ct.serviceType.service_internet === serviceType?.service_internet &&
                ct.serviceType.service_voice === serviceType?.service_voice
        })[0]?.value
        return result ? result : 1
    }

    const validateUniqueVlanId = (value) => {
        return value && networkWanList?.filter(nwl => nwl.id !== networkWan.id).map(nwl => nwl.vlanID).some(vlId => (vlId === value)) ? t('network.wan.message.error.VLAN_ID_ALREADY_USED') : ''
    }

    const hasPortMappingAndNAPT = (connectionValue) => {
        let VOICE = 8
        let TR_069 = 1
        let VOICE_TR_069 = 9
        return connectionValue !== TR_069 && connectionValue !== VOICE && connectionValue !== VOICE_TR_069
    }

    const enablePrefixRequest = (connectionValue) => {
        let VOICE = 8
        let TR_069 = 1
        let VOICE_TR_069 = 9
        return connectionValue !== TR_069 && connectionValue !== VOICE && connectionValue !== VOICE_TR_069
    }

    const getMaxMtu = () => {
        return networkWan.mode === Mode.PPPOE ? 1492 : 1500
    }
    const mtuValidator = (value) => {
        let min = 1280
        let max = getMaxMtu()
        if ((value < min) || (value > max)) {
            return false
        }

        return true
    }

    const updateMtu = (mtu) => {
        interfaceWan.mtu = mtu
        setInterfaceWan({ ...interfaceWan })

        if (networkWan.mode === Mode.PPPOE) {
            pppoeConfig.mtu = mtu
            setPppoeConfig({ ...pppoeConfig })
        }
    }

    const changedBetweenProtocols = () => {
        return (
            ((originalNetworkWanProtocol === Protocol.IPV6 || originalNetworkWanProtocol === Protocol.BOTH) && networkWan.protocol === Protocol.IPV4)
            || ((originalNetworkWanProtocol === Protocol.IPV4 || originalNetworkWanProtocol === Protocol.BOTH) && networkWan.protocol === Protocol.IPV6))
    }

    const networkWanForm = () => {
        return <React.Fragment>
            <div className="network-wans-new-button">
                <Button
                    id='network-wans-new'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={e => goBackToList()}
                ></Button>
            </div>
            <div className='section-divider'></div>
            <div className="network-wan-section">
                <div className='card mt2'>
                    <div className='subtitle'>{networkWan.id ? t('network.wan.title.UPDATE') + ' ' + networkWan.id : t('network.wan.title.INSERT_NEW_WAN')}</div>
                    {ponStatus?.current_mode === 'gpon' ?
                        null
                        :
                        <Checkbox
                            id='network-wan-vlan-enable'
                            name='vlan_enable'
                            label={<Translator path="network.wan.label.ACTIVATE_VLAN"></Translator>}
                            value={networkWan.vlan_enable}
                            clearErrors={true}
                            toggleFn={(e) => {
                                setNetworkWan({ ...networkWan, vlan_enable: !networkWan.vlan_enable })
                            }}
                        ></Checkbox>
                    }

                    <Checkbox id='network-wan-multicast-vlan-enable'
                        name='multicast-vlan_enable'
                        label={<Translator path="network.wan.label.ENABLE_MULTICAST_VLAN"></Translator>}
                        value={enableMultcastVlan}
                        clearErrors={true}
                        toggleFn={(e) => {
                            let MultcastVlanCB = !enableMultcastVlan
                            if (!MultcastVlanCB) {
                                networkWan.multicast_vlanID = 0
                                setNetworkWan({ ...networkWan })
                            }
                            setEnableMultcastVlan(MultcastVlanCB)
                        }}
                    ></Checkbox>

                    <span className="itb-input-error-text">
                        {!networkWan.vlan_enable && networkWanList?.filter(nwl => nwl.id !== networkWan.id).map(nwl => nwl.vlan_enable).some(vlanEnabled => !vlanEnabled) ? t('network.wan.message.error.ONLY_ONE_WAN_WITH_DISABLED_VLAN_IS_ENABLED') : ''}
                    </span>
                    {
                        networkWan.vlan_enable &&
                        <Input id='network-wan-vlan-id'
                            name='vlanID'
                            type="text"
                            label={<Translator path="network.wan.label.VLAN_ID"></Translator>}
                            value={networkWan.vlanID}
                            onChange={(e) => {

                                networkWan.vlanID = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                setNetworkWan({ ...networkWan })
                            }}
                            validators={[extraValidators.nonASCII, extraValidators.isNumber, { fn: extraValidators.value, params: { min: 1, max: 4095 } }, validateUniqueVlanId]}
                            errorMessage={!networkWan.vlanID ? t('common.message.error.REQUIRED_FIELD') : ''}
                        ></Input>
                    }

                    {enableMultcastVlan &&
                        <Input id='network-wan-multicast-vlan-id'
                            name='multicast-vlanID'
                            type="text"
                            label={<Translator path="network.wan.label.MULTICAST_VLAN_ID"></Translator>}
                            value={networkWan.multicast_vlanID}
                            onChange={(e) => {

                                networkWan.multicast_vlanID = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                setNetworkWan({ ...networkWan })
                            }}
                            validators={[
                                extraValidators.nonASCII,
                                extraValidators.isNumber,
                                { fn: extraValidators.value, params: { min: 1, max: 4095 } },
                                validateUniqueVlanId
                            ]}
                            errorMessage={!networkWan.multicast_vlanID ? t('common.message.error.REQUIRED_FIELD') : ''}
                        ></Input>
                    }

                    <Select
                        id='network-wan-mode'
                        name='mode'
                        label={<Translator path="network.wan.label.MODE"></Translator>}
                        options={[{ value: Mode.DHCP, text: 'DHCP' }, { value: Mode.IPOE, text: 'Static' }, { value: Mode.PPPOE, text: 'PPPoE' }, { value: Mode.BRIDGED, text: 'Bridged' }]}
                        value={networkWan.mode}
                        dependentValues={networkWan.mode}
                        clearErrors={true}
                        onChange={(e) => {
                            let mode = Number(e.target.value)
                            networkWan.mode = mode

                            if (networkWan.mode === Mode.BRIDGED) {
                                networkWan.napt_enable = false
                                setServiceType({ id: serviceType.id, ...WANServiceTypeOptions.filter(ct => ct.text === 'Internet')[0].serviceType })
                                networkWan.address_mode_v6 = ipv6AddressMode.NONE
                            } else if (hasPortMappingAndNAPT(serviceType.id)) {
                                networkWan.napt_enable = true
                            }

                            if (networkWan.mode === Mode.IPOE) {
                                networkWan.dns_v4.automatic = false
                                networkWan.dns_v6.automatic = false
                                networkWan.address_mode_v6 = ipv6AddressMode.STATIC
                            }

                            if (params.id === 'new' && networkWan.mode === Mode.BRIDGED) {
                                networkWan.dns_v4.automatic = false
                                networkWan.dns_v6.automatic = false
                                networkWan.address_mode_v6 = ipv6AddressMode.NONE
                            } else if (params.id === 'new' && networkWan.mode === Mode.IPOE) {
                                networkWan.dns_v4.automatic = false
                                networkWan.dns_v6.automatic = false
                                networkWan.address_mode_v6 = ipv6AddressMode.STATIC
                            } else if (params.id === 'new' && (networkWan.mode === Mode.DHCP || networkWan.mode === Mode.PPPOE)) {
                                networkWan.dns_v4.automatic = true
                                networkWan.dns_v6.automatic = true
                                networkWan.address_mode_v6 = ipv6AddressMode.STATELESS_DHCPv6
                            }
                            setNetworkWan({ ...networkWan })

                            let mtu = (mode === Mode.PPPOE) ? 1492 : 1500
                            updateMtu(mtu)
                        }}
                        errorMessage={networkWan.default_gateway && networkWan.mode === 0 ? 'WAN bridge não pode ser rota padrão' : ''}
                    ></Select>
                    <div>
                        {((params.id !== 'new') && ((originalNetworkWanMode === Mode.PPPOE && networkWan.mode !== Mode.PPPOE)
                            || (originalNetworkWanMode !== Mode.PPPOE && networkWan.mode === Mode.PPPOE))) ?

                            <div className="network-wan-mode-warning">
                                <strong><Translator path="network.wan.message.warning.NETWORK_MODE_CHANGED"></Translator></strong>
                                <br></br>
                            </div>

                            : null
                        }
                    </div>
                    <Select
                        id='network-wan-protocol'
                        name='protocol'
                        label={<Translator path="common.label.PROTOCOL"></Translator>}
                        value={networkWan.protocol}
                        clearErrors={true}
                        options={system?.ipv6?.enabled ? [{ value: Protocol.IPV4, text: 'IPv4' }, { value: Protocol.IPV6, text: 'IPv6' }, { value: Protocol.BOTH, text: 'IPv4/IPv6' }] : [{ value: Protocol.IPV4, text: 'IPv4' }]}
                        onChange={(e) => {
                            if (networkWan.protocol === Protocol.IPV4){
                                networkWan.prefix_enable = true
                            }

                            networkWan.protocol = Number(e.target.value)

                            if (networkWan.protocol === Protocol.IPV4 || networkWan.protocol === Protocol.BOTH) {
                                networkWan.dns_v4.automatic = (networkWan.mode === Mode.DHCP || networkWan.mode === Mode.PPPOE)
                            }

                            if (networkWan.protocol === Protocol.IPV6 || networkWan.protocol === Protocol.BOTH) {
                                networkWan.dns_v6.automatic = (networkWan.mode === Mode.DHCP || networkWan.mode === Mode.PPPOE)
                            }

                            setNetworkWan({ ...networkWan })
                        }}
                    ></Select>
                    <div>
                        {changedBetweenProtocols() ?

                            <div className="network-wan-protocol-warning">
                                <strong><Translator path="network.wan.message.warning.NETWORK_PROTOCOL_CHANGED"></Translator></strong>
                                <br></br>
                            </div>

                            : null
                        }
                    </div>
                    {
                        system?.ipv6?.enabled ?
                            null
                            :
                            <div>
                                <p className='network-wan-protocol-info'>
                                    {t(`network.wan.message.warning.IPV6_OPTIONS_DISABLED`)}
                                    <br />
                                    {t(`network.wan.message.warning.IPV6_PROTOCOL_ENABLE`)}
                                    <a href='#/ipv6/status'>{" " + t(`network.wan.label.IPV6_STATUS`)}</a>
                                </p>
                            </div>
                    }
                    <Select
                        id='network-wan-connection-type'
                        name='connection_type'
                        label={<Translator path="network.wan.label.CONNECTION_TYPE"></Translator>}
                        options={networkWan.mode === Mode.BRIDGED ? WANServiceTypeBridgedOptions : WANServiceTypeOptions}
                        value={getConnectionValue(serviceType)}
                        onChange={(e) => {
                            setServiceType({ id: serviceType.id, ...WANServiceTypeOptions.filter(ct => ct.value === Number(e.target.value))[0].serviceType })

                            if (!hasPortMappingAndNAPT(getConnectionValue(WANServiceTypeOptions.filter(ct => ct.value === Number(e.target.value))[0].serviceType))) {
                                fetchWanLan('new', backend, setWanLan, globals.containsFourLanPorts())
                                setNetworkWan({ ...networkWan, napt_enable: false })
                            }
                            else if (networkWan.mode !== Mode.BRIDGED) {
                                setNetworkWan({ ...networkWan, napt_enable: true })
                            }
                        }}
                    ></Select>

                    {
                        (networkWan.mode === Mode.BRIDGED || !hasPortMappingAndNAPT(getConnectionValue(serviceType))) ? null :
                            <Checkbox id='network-wan-napt-enable'
                                name='napt_enable'
                                label={<Translator path="network.wan.label.ACTIVATE_NAPT"></Translator>}
                                value={networkWan.napt_enable}
                                toggleFn={(e) => {
                                    networkWan.napt_enable = !networkWan.napt_enable;
                                    setNetworkWan({ ...networkWan })
                                }}
                                validators={[]}
                            ></Checkbox>
                    }

                    {
                        (networkWan.mode === Mode.BRIDGED || (networkWan.protocol === Protocol.IPV4)) ? null :
                            <Checkbox id='network-wan-prefix-enable'
                                name='prefix_enable'
                                label={<Translator path="network.wan.label.REQUEST_PREFIX"></Translator>}
                                value={networkWan.prefix_enable}
                                toggleFn={(e) => {
                                    setNetworkWan({ ...networkWan, prefix_enable: !networkWan.prefix_enable })
                                }}
                                disabled={!enablePrefixRequest(getConnectionValue(serviceType))}
                                validators={[]}
                            ></Checkbox>
                    }

                    {
                        interfaceWan && networkWan.mode !== Mode.BRIDGED && <>
                            <Input id='network-wan-mtu'
                                name='mtu'
                                type="text"
                                label={<Translator path="network.wan.label.MTU"></Translator>}
                                value={interfaceWan.mtu}
                                onChange={(e) => {
                                    let mtu = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                    updateMtu(mtu)
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.isNumber]}
                                errorMessage={mtuValidator(interfaceWan.mtu) ? '' : t('common.message.error.VALUE_MIN_MAX_FIELD', { min: 1280, max: getMaxMtu() })}
                            ></Input>
                        </>
                    }
                </div>
                {
                    interfaceWan && networkWan.mode === Mode.IPOE && (networkWan.protocol === Protocol.IPV4 || networkWan.protocol === Protocol.BOTH) &&
                    <>
                        <div className='card mt2'>
                            <div className='subtitle'><Translator path="network.wan.title.IPV4_CONFIGURATION"></Translator></div>
                            <Input id='network-wan-ipv4-config-gateway'
                                name='gateway'
                                label={<Translator path="network.wan.label.GATEWAY_IPV4" required="true"></Translator>}
                                value={networkWan.gateway}
                                onChange={(e) => {
                                    setNetworkWan({ ...networkWan, gateway: e.target.value })
                                }}
                                validators={[
                                    extraValidators.nonASCII, 
                                    extraValidators.validateIPv4,
                                    extraValidators.validateIfNotLocalhost
                                ]}
                                errorMessage={!networkWan.gateway ? t('common.message.error.REQUIRED_FIELD') : ''}
                            ></Input>
                            <Input id='network-wan-ipv4-config-local-ip-address'
                                name='local_ip_address'
                                label={<Translator path="network.wan.label.IP_ADDRESS" required="true"></Translator>}
                                value={interfaceWan.ip4}
                                onChange={(e) => {
                                    setInterfaceWan({ ...interfaceWan, ip4: e.target.value })
                                }}
                                validators={[
                                    extraValidators.required, 
                                    extraValidators.nonASCII, 
                                    extraValidators.validateIPv4,
                                    extraValidators.validateIfNotLocalhost
                                ]}
                            ></Input>

                            <Input id='network-wan-ipv4-config-subnet-mask'
                                name='subnet_mask'
                                label={<Translator path="network.wan.label.NETMASK" required="true"></Translator>}
                                value={interfaceWan.netmask}
                                onChange={(e) => {
                                    setInterfaceWan({ ...interfaceWan, netmask: e.target.value })
                                }}
                                dependentValues={[interfaceWan.ip4, networkWan.gateway]}
                                validators={[
                                    extraValidators.required,
                                    extraValidators.nonASCII,
                                    extraValidators.validateSubNetMask,
                                ]}
                            ></Input>

                            <Checkbox id='network-wan-ipv4-config-request-dns'
                                name='request_dns'
                                label={<Translator path="network.wan.label.REQUEST_DNS"></Translator>}
                                value={networkWan.dns_v4.automatic}
                                toggleFn={(e) => {
                                    networkWan.dns_v4.automatic = !networkWan.dns_v4.automatic;
                                    setNetworkWan({ ...networkWan })
                                }}
                                disabled={networkWan.mode === Mode.IPOE ? true : false}
                                dependentValues={[networkWan.mode]}
                                validators={[]}
                                clearErrors='true'
                            ></Checkbox>
                            {
                                !networkWan.dns_v4.automatic && <>
                                    <Input id='network-wan-ipv4-config-dns1'
                                        name='dns1'
                                        label={<Translator path="network.wan.label.DNS_SERVER_1"></Translator>}
                                        value={networkWan.dns_v4.dns1}
                                        onChange={(e) => {
                                            networkWan.dns_v4.dns1 = e.target.value
                                            setNetworkWan({ ...networkWan })
                                        }}
                                        validators={[
                                            {fn: extraValidators.optionalValidators, params: {
                                                shouldValidate: () => networkWan.dns_v4.automatic === false,
                                                validators: [
                                                    {fn: extraValidators.validateIPv4, params: ''},
                                                    {fn: extraValidators.required, params: ''},
                                                ]
                                            }}, 
                                        ]}
                                    ></Input>
                                    <Input id='network-wan-ipv4-config-dns2'
                                        name='dns2'
                                        label={<Translator path="network.wan.label.DNS_SERVER_2"></Translator>}
                                        value={networkWan.dns_v4.dns2}
                                        onChange={(e) => {
                                            networkWan.dns_v4.dns2 = e.target.value
                                            setNetworkWan({ ...networkWan })
                                        }}
                                        validators={[
                                            {fn: extraValidators.optionalValidators, params: {
                                                shouldValidate: () => networkWan.dns_v4.automatic === false,
                                                validators: [
                                                    {fn: extraValidators.validateIPv4, params: ''},
                                                ]
                                            }}, 
                                        ]}
                                    ></Input>
                                </>
                            }
                        </div>
                    </>
                }
                {
                    interfaceWan && (networkWan.mode === Mode.DHCP || networkWan.mode === Mode.PPPOE) && (networkWan.protocol === Protocol.IPV4 || networkWan.protocol === Protocol.BOTH) &&
                    <div className='card mt2'>
                        <div className='subtitle'><Translator path="network.wan.title.IPV4_CONFIGURATION"></Translator></div>
                        <Checkbox id='network-wan-ipv4-config-request-dns'
                            name='request_dns'
                            label={<Translator path="network.wan.label.REQUEST_DNS"></Translator>}
                            value={networkWan.dns_v4.automatic}
                            toggleFn={(e) => {
                                networkWan.dns_v4.automatic = !networkWan.dns_v4.automatic;
                                setNetworkWan({ ...networkWan })
                            }}
                            disabled={networkWan.mode === Mode.IPOE ? true : false}
                            dependentValues={[networkWan.mode]}
                            validators={[]}
                            clearErrors='true'
                        ></Checkbox>
                        {
                            !networkWan.dns_v4.automatic && <>
                                <Input id='network-wan-ipv4-config-dns1'
                                    name='dns1'
                                    label={<Translator path="network.wan.label.DNS_SERVER_1"></Translator>}
                                    value={networkWan.dns_v4.dns1}
                                    onChange={(e) => {
                                        networkWan.dns_v4.dns1 = e.target.value
                                        setNetworkWan({ ...networkWan })
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => networkWan.dns_v4.automatic === false,
                                            validators: [
                                                {fn: extraValidators.validateIPv4, params: ''},
                                                {fn: extraValidators.required, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>
                                <Input id='network-wan-ipv4-config-dns2'
                                    name='dns2'
                                    label={<Translator path="network.wan.label.DNS_SERVER_2"></Translator>}
                                    value={networkWan.dns_v4.dns2}
                                    onChange={(e) => {
                                        networkWan.dns_v4.dns2 = e.target.value
                                        setNetworkWan({ ...networkWan })
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => networkWan.dns_v4.automatic === false,
                                            validators: [
                                                {fn: extraValidators.validateIPv4, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>
                            </>
                        }
                    </div>
                }
                {
                    interfaceWan && (networkWan.mode === Mode.IPOE) && (networkWan.protocol === Protocol.IPV6 || networkWan.protocol === Protocol.BOTH) &&
                    <div className='card mt2'>
                        <div className='subtitle'><Translator path="network.wan.title.IPV6_CONFIGURATION"></Translator></div>
                        <Input id='network-wan-ipv6-config-address'
                            name='address'
                            label={<Translator path="network.wan.label.IPV6_ADDRESS"></Translator>}
                            value={interfaceWan.ip6_list[0]}
                            onChange={(e) => {
                                interfaceWan.ip6_list[0] = e.target.value
                                setInterfaceWan({ ...interfaceWan })
                            }}
                            validators={[
                                extraValidators.nonASCII, 
                                extraValidators.validateIPv6WithPrefix, 
                                extraValidators.noIPv6LinkLocal, 
                                extraValidators.validateIfNotLocalhost
                            ]}
                        ></Input>
                        <Input id='network-wan-ipv6-config-gateway'
                            name='gateway'
                            label={<Translator path="network.wan.label.IPV6_GATEWAY"></Translator>}
                            value={networkWan.gateway_v6}
                            onChange={(e) => {
                                networkWan.gateway_v6 = e.target.value
                                setNetworkWan({ ...networkWan })
                            }}
                            validators={[
                                extraValidators.nonASCII, 
                                extraValidators.validateIPv6,
                                extraValidators.validateIfNotLocalhost,
                            ]}
                        ></Input>

                        <Checkbox id='network-wan-ipv6-config-request-dns'
                            name='request_dns'
                            label={<Translator path="network.wan.label.REQUEST_DNS"></Translator>}
                            value={networkWan.dns_v6.automatic}
                            toggleFn={(e) => {
                                networkWan.dns_v6.automatic = !networkWan.dns_v6.automatic;
                                setNetworkWan({ ...networkWan })
                            }}
                            validators={[]}
                            clearErrors='true'
                        ></Checkbox>
                        {
                            !networkWan.dns_v6.automatic && <>
                                <Input id='network-wan-ipv6-config-primary-dns1'
                                    name='dns1'
                                    label={<Translator path="network.wan.label.DNS_SERVER_1"></Translator>}
                                    value={networkWan.dns_v6.dns1}
                                    onChange={(e) => {
                                        networkWan.dns_v6.dns1 = e.target.value
                                        setNetworkWan({ ...networkWan })
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => networkWan.dns_v6.automatic === false,
                                            validators: [
                                                {fn: extraValidators.validateIPv6, params: ''},
                                                {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                                {fn: extraValidators.required, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>

                                <Input id='network-wan-ipv6-config-primary-dns2'
                                    name='dns2'
                                    label={<Translator path="network.wan.label.DNS_SERVER_2"></Translator>}
                                    value={networkWan.dns_v6.dns2}
                                    onChange={(e) => {
                                        networkWan.dns_v6.dns2 = e.target.value
                                        setNetworkWan({ ...networkWan })
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => networkWan.dns_v6.automatic === false,
                                            validators: [
                                                {fn: extraValidators.validateIPv6, params: ''},
                                                {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>
                            </>
                        }
                    </div>
                }
                {
                    interfaceWan && (networkWan.mode === Mode.DHCP || networkWan.mode === Mode.PPPOE) && (networkWan.protocol === Protocol.IPV6 || networkWan.protocol === Protocol.BOTH) &&
                    <div className='card mt2'>
                        <div className='subtitle'><Translator path="network.wan.title.IPV6_CONFIGURATION"></Translator></div>
                        <Checkbox id='network-wan-ipv6-config-request-dns'
                            name='request_dns'
                            label={<Translator path="network.wan.label.REQUEST_DNS"></Translator>}
                            value={networkWan.dns_v6.automatic}
                            toggleFn={(e) => {
                                networkWan.dns_v6.automatic = !networkWan.dns_v6.automatic;
                                setNetworkWan({ ...networkWan })
                            }}
                            validators={[]}
                            clearErrors='true'
                        ></Checkbox>
                        {networkWan.mode === Mode.DHCP || networkWan.mode === Mode.PPPOE ? <Select
                            id='network-wan-mode-v6'
                            name='mode_v6'
                            label={<Translator path="network.wan.label.IPV6_ADDR_MODE"></Translator>}
                            options={[{ value: ipv6AddressMode.STATELESS_DHCPv6, text: 'Stateless DHCPv6 (SLAAC)' }, 
                                    { value: ipv6AddressMode.STATEFUL_DHCPv6, text: 'Stateful DHCPv6' }, 
                                    { value: ipv6AddressMode.AUTO_DETECT, text: 'Auto Detect Mode' }]}

                            value={networkWan.address_mode_v6}
                            dependentValues={networkWan.address_mode_v6}
                            clearErrors={true}
                            onChange={(e) => {
                                networkWan.address_mode_v6 = Number(e.target.value)
                                setNetworkWan({ ...networkWan })
                            }}
                        ></Select> : null}
                        {
                            !networkWan.dns_v6.automatic && <>
                                <Input id='network-wan-ipv6-config-primary-dns1'
                                    name='dns1'
                                    label={<Translator path="network.wan.label.DNS_SERVER_1"></Translator>}
                                    value={networkWan.dns_v6.dns1}
                                    onChange={(e) => {
                                        networkWan.dns_v6.dns1 = e.target.value
                                        setNetworkWan({ ...networkWan })
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => networkWan.dns_v6.automatic === false,
                                            validators: [
                                                {fn: extraValidators.validateIPv6, params: ''},
                                                {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                                {fn: extraValidators.required, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>

                                <Input id='network-wan-ipv6-config-primary-dns2'
                                    name='dns2'
                                    label={<Translator path="network.wan.label.DNS_SERVER_2"></Translator>}
                                    value={networkWan.dns_v6.dns2}
                                    onChange={(e) => {
                                        networkWan.dns_v6.dns2 = e.target.value
                                        setNetworkWan({ ...networkWan })
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => networkWan.dns_v6.automatic === false,
                                            validators: [
                                                {fn: extraValidators.validateIPv6, params: ''},
                                                {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>
                            </>
                        }
                    </div>
                }

                {
                    interfaceWan && networkWan.mode === Mode.PPPOE && pppoeConfig &&
                    <div className='card mt2'>
                        <div className='subtitle'><Translator path="network.wan.title.PPPOE_CONFIGURATION"></Translator></div>
                        <Input id='network-wan-pppoe-config-username'
                            name='username'
                            label={<Translator path="network.wan.label.USER" required="true"></Translator>}
                            value={pppoeConfig.username}
                            onChange={(e) => {
                                pppoeConfig.username = e.target.value
                                setPppoeConfig({ ...pppoeConfig })
                            }}
                            validators={[extraValidators.required, extraValidators.nonASCII, { fn: extraValidators.size, params: { min: 0, max: 127 } }]}
                        ></Input>
                        <Input id='network-wan-pppoe-config-password'
                            name='password'
                            type="password"
                            label={<Translator path="network.wan.label.PASSWORD" required="true"></Translator>}
                            value={pppoeConfig.password}
                            onChange={(e) => {
                                pppoeConfig.password = e.target.value
                                setPppoeConfig({ ...pppoeConfig })
                            }}
                            validators={[extraValidators.required, extraValidators.nonASCII, { fn: extraValidators.size, params: { min: 0, max: 127 } }]}
                        ></Input>

                        <Input id='network-wan-pppoe-config-server'
                            name='server'
                            label={<Translator path="network.wan.label.SERVER"></Translator>}
                            value={pppoeConfig.server}
                            onChange={(e) => {
                                pppoeConfig.server = e.target.value
                                setPppoeConfig({ ...pppoeConfig })
                            }}
                            validators={[extraValidators.nonASCII, { fn: extraValidators.size, params: { min: 0, max: 41 } }]}
                        ></Input>
                        <Input id='network-wan-pppoe-config-service'
                            name='service'
                            label={<Translator path="network.wan.label.SERVICE_NAME"></Translator>}
                            value={pppoeConfig.service}
                            onChange={(e) => {
                                pppoeConfig.service = e.target.value
                                setPppoeConfig({ ...pppoeConfig })
                            }}
                            validators={[extraValidators.nonASCII, { fn: extraValidators.size, params: { min: 0, max: 41 } }]}
                        ></Input>
                    </div>
                }
                {
                    hasPortMappingAndNAPT(getConnectionValue(serviceType)) ?
                        <div className='card mt2'>
                            <div className='subtitle'><Translator path="network.wan.title.PORT_MAPPING"></Translator></div>
                            <div className="network-wan-port-mapping-wrapper">
                                <div className="row">
                                    <div className="columm">
                                        {
                                            wanLan?.lan_list.map((pm, key) =>
                                                isRootEnabled(pm) && (pm.lan_id.startsWith(LanWlanInterfaceName.lan1) || pm.lan_id.startsWith(LanWlanInterfaceName.wlan0)) ?
                                                    (isVapEnabled(pm) ? (
                                                        <Checkbox key={key} id={`network-wan-port-mapping-${key}-enabled`}
                                                            name='enabled'
                                                            label={(pm.lan_id === LanWlanInterfaceName.lan1 ? LanCleanId.lan1 : pm.lan_id) + (shouldDisable(pm) ? '*' : '')}
                                                            value={wanLan.lan_list[key].enabled}
                                                            toggleFn={(e) => {
                                                                wanLan.lan_list[key].enabled = !wanLan.lan_list[key].enabled;
                                                                setWanLan({ ...wanLan })
                                                            }}
                                                            validators={[]}
                                                        ></Checkbox>
                                                    ) : ''
                                                    ) : ''
                                            )
                                        }
                                    </div>
                                    <div className="columm">
                                        {
                                            wanLan?.lan_list.map((pm, key) =>
                                                isRootEnabled(pm) && (pm.lan_id.startsWith(LanWlanInterfaceName.lan2) || pm.lan_id.startsWith(LanWlanInterfaceName.wlan1)) ?
                                                    (isVapEnabled(pm) ?
                                                        (<Checkbox key={key} id={`network-wan-port-mapping-${key}-enabled`}
                                                            name='enabled'
                                                            label={(pm.lan_id === LanWlanInterfaceName.lan2 ? LanCleanId.lan2 : pm.lan_id) + (shouldDisable(pm) ? '*' : '')}
                                                            value={wanLan.lan_list[key].enabled}
                                                            toggleFn={(e) => {
                                                                wanLan.lan_list[key].enabled = !wanLan.lan_list[key].enabled;
                                                                setWanLan({ ...wanLan })
                                                            }}
                                                            validators={[]}
                                                        ></Checkbox>
                                                        ) : ''
                                                    ) : ''
                                            )
                                        }
                                    </div>
                                    <div className="columm">
                                        {
                                            wanLan?.lan_list.map((pm, key) =>
                                                (pm.lan_id.startsWith(LanWlanInterfaceName.lan3)) ?
                                                    (<Checkbox key={key} id={`network-wan-port-mapping-${key}-enabled`}
                                                        name='enabled'
                                                        label={(pm.lan_id === LanWlanInterfaceName.lan3 ? LanCleanId.lan3 : pm.lan_id) + (shouldDisable(pm) ? '*' : '')}
                                                        value={wanLan.lan_list[key].enabled}
                                                        toggleFn={(e) => {
                                                            wanLan.lan_list[key].enabled = !wanLan.lan_list[key].enabled;
                                                            setWanLan({ ...wanLan })
                                                        }}
                                                        validators={[]}
                                                    ></Checkbox>
                                                    ) : ''
                                            )
                                        }
                                    </div>
                                    <div className="columm">
                                        {
                                            wanLan?.lan_list.map((pm, key) =>
                                                (pm.lan_id.startsWith(LanWlanInterfaceName.lan4)) ?
                                                    (<Checkbox key={key} id={`network-wan-port-mapping-${key}-enabled`}
                                                        name='enabled'
                                                        label={(pm.lan_id === LanWlanInterfaceName.lan4 ? LanCleanId.lan4 : pm.lan_id) + (shouldDisable(pm) ? '*' : '')}
                                                        value={wanLan.lan_list[key].enabled}
                                                        toggleFn={(e) => {
                                                            wanLan.lan_list[key].enabled = !wanLan.lan_list[key].enabled;
                                                            setWanLan({ ...wanLan })
                                                        }}
                                                        validators={[]}
                                                    ></Checkbox>
                                                    ) : ''
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="disabled-ports-warning">
                                <strong><Translator path="network.wan.message.warning.DISABLED_PORTS"></Translator></strong>
                            </div>
                        </div>
                        : <></>
                }

            </div>
        </React.Fragment>
    }

    const validateBefore = async () => {
        let ipv4 = interfaceWan.ip4 && interfaceWan.netmask
        let ipv6 = interfaceWan.ip6_list[0]
        if (networkWan.mode === Mode.IPOE) {
            if (!ipv4 && !ipv6) {
                setErrorMessage(t('network.wan.message.error.MUST_HAVE_IPV4_OR_IPV6'))
                return false
            }

            if (ipv4) {
                if (!networkWan.gateway) {
                    setErrorMessage(t('network.wan.message.error.IPV4_GATEWAY_IS_EMPTY'))
                    return false
                }

                if (networkWan.dns_v4.automatic) {
                    setErrorMessage(t('network.wan.message.error.DNS_IPV4_REQUIRED'))
                    return false
                }

                if (networkWan.gateway && networkWan.gateway !== '0.0.0.0') {
                    if (!sameSubnet(networkWan.gateway, interfaceWan.ip4, interfaceWan.netmask)) {
                        setErrorMessage(t('network.wan.message.error.GATEWAY_AND_IPV4_ARE_NOT_IN_SAME_SUBNET'))
                        return false
                    }

                    if (networkWan.gateway === interfaceWan.ip4) {
                        setErrorMessage(t('network.wan.message.error.GATEWAY_AND_IPV4_ADDRESS_MUST_BE_DIFFERENT'))
                        return false
                    }

                    if (lan.netmask === interfaceWan.netmask && sameSubnet(lan.ip4, interfaceWan.ip4, interfaceWan.netmask)) {
                        setErrorMessage(t('network.wan.message.error.IPV4_ADDRESS_CANNOT_BE_IN_THE_SAME_SUBNET_OF_LAN'))
                        return false
                    }
                }
            }

            if (ipv6) {
                if (!validateIPv6Settings()) {
                    return false
                }
            }
        }

        if (networkWan.mode === Mode.PPPOE) {
            if (ipv6) {
                if (!validateIPv6Settings()) {
                    return false
                }
            }
        }

        return true
    }

    const sameSubnet = (ip1, ip2, subnetMask) => {
        let ipMod = Ip
        return ipMod.toLong(ipMod.mask(ip1, subnetMask)) === ipMod.toLong(ipMod.mask(ip2, subnetMask))
    }

    const validateIPv6Settings = () => {

        if (!networkWan.gateway_v6) {
            setErrorMessage(t('network.wan.message.error.IPV6_GATEWAY_IS_EMPTY'))
            return false
        }

        if (networkWan.gateway_v6 && networkWan.gateway_v6 !== '::') {
            if (networkWan.gateway_v6 === interfaceWan.ip6_list[0]) {
                setErrorMessage(t('network.wan.message.error.IPV6_ADDRESS_AND_GATEWAY_CANNOT_BE_EQUALS'))
                return false
            }
        }

        return true
    }

    const cleanUP = async () => {
        let oldWan = null;
        let oldInterface = null;
        let oldPppoe = null;
        await fetchNetworkWan(params.id === 'new' ? null : params.id, backend, (wan) => { oldWan = wan })
        await fetchInterface(params.id === 'new' ? 'new' : networkWan.id, backend, (itf) => { oldInterface = itf })
        await fetchPppoe(params.id === 'new' ? 'new' : networkWan.id, backend, (pppoe) => { oldPppoe = pppoe })

        if (!oldWan || !oldInterface || !oldPppoe) {
            setErrorMessage(t('network.wan.message.error.ERROR_SERVER_RETRIEVE'))
            return false
        }

        if (networkWan.mode === Mode.BRIDGED) {
            networkWan.dns_v4.dns1 = oldWan.dns_v4.dns1
            networkWan.dns_v4.dns2 = oldWan.dns_v4.dns2
            networkWan.dns_v4.dns3 = oldWan.dns_v4.dns3
            networkWan.dns_v6.dns1 = oldWan.dns_v6.dns1
            networkWan.dns_v6.dns2 = oldWan.dns_v6.dns2
            networkWan.dns_v6.dns3 = oldWan.dns_v6.dns3
            networkWan.gateway = oldWan.gateway
            networkWan.gateway_v6 = oldWan.gateway_v6

            interfaceWan = oldInterface
            pppoeConfig = oldPppoe
        }

        if (networkWan.mode === Mode.IPOE) {
            pppoeConfig = oldPppoe
        }

        if (networkWan.mode === Mode.DHCP) {
            networkWan.gateway = oldWan.gateway
            networkWan.gateway_v6 = oldWan.gateway_v6

            let mtu = interfaceWan.mtu
            interfaceWan = oldInterface
            interfaceWan.mtu = mtu
            pppoeConfig = oldPppoe
        }

        if (networkWan.mode === Mode.PPPOE) {
            networkWan.gateway = oldWan.gateway
            networkWan.gateway_v6 = oldWan.gateway_v6

            let mtu = interfaceWan.mtu
            interfaceWan = oldInterface
            interfaceWan.mtu = mtu
        }

        setInterfaceWan(interfaceWan)
        setPppoeConfig(pppoeConfig)
        setNetworkWan(networkWan)

        return true
    }


    const translateErrorMessagesFromBackend = (text) => {
        if (text === 'There is another connection with the same VLAN configuration') {
            return t('network.wan.message.error.VLAN_ALREADY_IN_USE')
        } else {
            return text
        }
    }


    const submit = async () => {

        if (! await validateBefore()) {
            setError(true)
            return
        }

        if (saving || error || success) return

        setSaving(true)

        if (!await cleanUP()) {
            setSaving(false)
            setError(true)
            return
        }

        let ok = null

        ok = await saveNetworkWan(backend, networkWan, setErrorMessage, false)
        if (ok) {
            params.id = ok.id
        } else {
            setSaving(false)
            setError(true)
            return
        }

        interfaceWan.id = params.id
        ok = await saveInterface(backend, interfaceWan, false)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        pppoeConfig.id = params.id
        if (pppoeConfig && networkWan.mode === 2) {
            ok = await savePpppoe(backend, pppoeConfig, false)
            if (!ok) {
                setSaving(false)
                setError(true)
                return
            }
        }

        serviceType.id = params.id
        ok = await saveServiceType(backend, serviceType, false)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        wanLan.id = params.id
        ok = await saveWanLan(backend, wanLan, true)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        setTimeout(() => {
            if (networkWan.isCreate) {
                history.push(`/network/wan/`)
            }
        }, 500)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
        if (success)
            history.push(`/network/wan/`)
    }

    return (
        !networkWan || !ponStatus ? <Loading show={true}></Loading> :

            <div id='network-wan-edit-page' className='with-tabs'>
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="network.wan.title.WAN"></Translator></div>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    success={success}
                    continueFn={dismissModal}
                    errorText={[t('network.wan.message.error.ERROR_SERVER'), ' ', translateErrorMessagesFromBackend(errorMessage)]}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                {!isFormSegment ? <Form
                    onSubmit={submit}
                    buttonId='button-save'
                    isPristine={false}
                    disableButton={
                        (networkWan.vlan_enable && !networkWan.vlanID) ||
                        (enableMultcastVlan && !networkWan.multicast_vlanID) ||
                        (interfaceWan && !mtuValidator(interfaceWan.mtu)) ||
                        ((networkWan.protocol === Protocol.IPV6 || networkWan.protocol === Protocol.BOTH) && system?.ipv6?.enabled === false)
                    }
                >
                    {networkWanForm()}
                </Form> :

                    <FormSegment
                        title={formSegment.title}
                        active={formSegment.active}
                        nextLabel={formSegment.nextLabel}
                        nextClicked={submit}
                    >
                        {networkWanForm()}
                    </FormSegment>}

            </div>
    )
}
