import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import Tabs from '../../../components/tabs/tabs'
import NetworkWanList from './network-wan/network-wans'
import NetworkWan from './network-wan/network-wan'
import NetworkLan from './network-lan/network-lan'
import NetworksWifi from './wifi/networks-wifi';
import WifiEdit from './wifi/wifi-edit';
import WifiRadio from './wifi/wifi-radio';
import { Wifi5GStatus, Wifi24GStatus } from './wifi/wifi-status';
import WifiConnectedDevice from './wifi/wifi-connected-devices'
import { useTranslation } from 'react-i18next';
import DhcpLeasePage from './network-lan/dhcp-lease-page'
import DhcpActiveClientsPage from './network-lan/dhcp-active-clients/dhcp-active-clients'
import NetworksState from './networks-context'
import NetworkMesh from './mesh/settings/network-mesh'
import MeshState from './mesh/mesh-context'
import MeshDevicePage from './mesh/mesh-device'
import MeshOnboardingPage from './mesh/mesh-onboarding'
/*IMPORTS_TAG*/


export default function NetworkPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: 'WAN', route: '/network/wan' },
        { label: 'LAN', route: '/network/lan' },
        { label: 'WiFi', route: '/network/wifi' },
        { label: 'INMESH', route: '/network/mesh/settings' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.NETWORK'))
        header.backRoute.set('/network/wan')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('network') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <NetworksState>
            <MeshState>
                <Tabs
                    data={tabs}
                    route={history.location.pathname}
                    activateFn={changeRoute}
                ></Tabs>

                <Switch>
                    <Route path='/network/wan/:id' render={(props) => {
                        return <NetworkWan {...props} ></NetworkWan>
                    }}></Route>
                    
                    <Route path='/network/wan' render={(props) => {
                        return <NetworkWanList {...props} ></NetworkWanList>
                    }}></Route>
                    
                    <Route path='/network/lan' exact render={(props) => {
                        return <NetworkLan {...props} ></NetworkLan>
                    }}></Route>
                    
                    <Route path='/network/wifi/radio' exact render={(props) => {
                        return <WifiRadio {...props} ></WifiRadio>
                    }}></Route>
                    
                    <Route path='/network/wifi/status/radio5ghz' exact render={(props) => {
                        return <Wifi5GStatus {...props} ></Wifi5GStatus>
                    }}></Route>
                    
                    <Route path='/network/wifi/status/radio2_4ghz' exact render={(props) => {
                        return <Wifi24GStatus {...props} ></Wifi24GStatus>
                    }}></Route>
                    
                    <Route path='/network/wifi/connected-device/:id' exact render={(props) => {
                        return <WifiConnectedDevice {...props} ></WifiConnectedDevice>
                    }}></Route>
                    
                    <Route path='/network/wifi/:id' render={(props) => {
                        return <WifiEdit {...props} ></WifiEdit>
                    }}></Route>
                    
                    <Route path='/network/wifi' render={(props) => {
                        return <NetworksWifi {...props} ></NetworksWifi>
                    }}></Route>
                    
                    <Route path='/network/lan/dhcp-lease' render={(props) => {
                        return <DhcpLeasePage {...props} ></DhcpLeasePage>
                    }}></Route>
                    
                    <Route path='/network/lan/dhcp-active-clients' render={(props) => {
                        return <DhcpActiveClientsPage {...props} ></DhcpActiveClientsPage>
                    }}></Route>
                    
                    <Route path='/network/mesh/settings' render={(props) => {
                        return <NetworkMesh {...props} ></NetworkMesh>
                    }}></Route>
                    
                    <Route path='/network/mesh/device/:id' render={(props) => {
                        return <MeshDevicePage {...props} ></MeshDevicePage>
                    }}></Route>
                    
                    <Route path='/network/mesh/onboarding' render={(props) => {
                        return <MeshOnboardingPage {...props} ></MeshOnboardingPage>
                    }}></Route>
                    
                    {/*ROUTES_TAG*/}
                </Switch>
            </MeshState>
        </NetworksState>
    )
}
