import React, { useContext } from "react"
import MenuEntry from "../../../../components/menu/menu-entry"
import { GlobalsContext } from "../../../globals-context"
import { SearchContext } from "../search-context"

export default function DisplayableMenuEntry(props) {

    const globals = useContext(GlobalsContext)
    const searchContext = useContext(SearchContext)

    const featureDisplay = (id) =>{
        return globals.containsFeature(id)
    }

    const searchDisplay = (id) => {
        return searchContext.displayMenus?.has(id)
    }

    return (
        <div>{
            featureDisplay(props.id) && searchDisplay(props.id) && <MenuEntry {...props} />
        }</div>
    )
}