const CommonValidators = {
    required: async(value) => {

        return value === '' ? 'Este campo é obrigatório' : ''
    },

    alphaNumeric: async(value) => {
        
        if(!value.match(/^[0-9a-zA-Z]+$/))
            return 'Este campo deve conter apenas letras e números'
        
        return ''
    },

    lettersAndSpace: async(value) => {

        if(!value.match(/^[a-zA-Z|\s]+$/))
            return 'Este campo deve conter apenas letras'

        return ''
    },

    onlySpaceBetween: async(value) => {

        if (value.length > value.trim().length)
            return 'Não é permitido uso de espaço no começo e fim.'

        return ''
    },

    nonASCII: async(value) => {

        if(!value) return ''

        for(let i = 0; i < value.length; i++){

            let charCode = value.charCodeAt(i)

            if(charCode < 32 || charCode > 127)
                return 'Contém caracteres inválidos'
        }

        return ''
    },

    size: async(value, {min,max}) => {

        if(value.length < min || value.length > max)
            return `O campo deve conter entre ${min} e ${max} caracteres`

        return ''
    },

    isNumber:  async(value) => {
        if(isNaN(value))
            return 'Valor deve ser numérico'

        return ''
    },

    value: async(value, {min,max}) => {
        if(((min || min === 0) && value < min) || ((max || max === 0) && value > max)) {
            if (!min && min !== 0) return `O campo deve possuir valor menor ou igual a ${max}`
            else if (!max && max !== 0) return `O campo deve possuir valor maior ou igual a ${min}`
            else return `O campo deve possuir valor entre ${min} e ${max}`
        }

        return ''
    }

}

export default CommonValidators