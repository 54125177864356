import React, { useContext, useState } from 'react'
import { BackendContext } from '../backend/backend'
import { fetchLan } from './resources/lan'
import { fetchUser } from './resources/user'
import macutils from './utils/macutils'
import MenuID from './utils/menu'

export const GlobalsContext = React.createContext({})

export default function Globals({children}) {

    const backend = useContext(BackendContext)

    const [data, setData] = useState({})

    const updateUser = async() => {
        const user = await fetchUser(backend)
        if (user) {
            data.username = user.username
            data.password = user.password
            setData({...data})
        }
    }

    const getDeviceID = async () => {
        if ('deviceid' in data) {
            return data['deviceid']
        }

        const result = await fetchLan(backend)

        if (result)
            return macutils.getDeviceIdFromMac(result.mac)
        else
            return ''
    }

    const setItem = (key, value) => {
        data[key] = value
        setData({...data})
    }

    const getItem = (key) => {
        return key in data ? data[key] : null
    }

    const deleteItem = (key) => {
        delete data[key]
        setData({...data})
    }

    const containsFeature = (feature) => {
        let compatible_devices

        switch (feature) {
            case MenuID.Voip.name:
                compatible_devices = [
                    'Bifrost mock',
                    '121AC',
                    'AX1800V'
                ]
                break;
        
            default:
                return true
        }

        return compatible_devices.some(device => {
            return sessionStorage.getItem('onu_model')?.toUpperCase().includes(device.toUpperCase())
        })
    }

    const containsFourLanPorts = () => {
        let four_lan_ports_devices = [
            'AX1800',
            'AX1800V'
        ]

        return four_lan_ports_devices.some(device => {
            return sessionStorage.getItem('onu_model')?.toUpperCase().includes(device.toUpperCase())
        })
    }

    const containsAxSupport = () => {
        let ax_devices = [
            'Bifrost mock',
            'AX1800',
            'AX1800V'
        ]

        return ax_devices.some(device => {
            return sessionStorage.getItem('onu_model')?.toUpperCase().includes(device.toUpperCase())
        })
    }

    return <GlobalsContext.Provider value={{
        setItem: setItem,
        getItem: getItem,
        deleteItem: deleteItem,
        updateUser: updateUser,
        getDeviceID: getDeviceID,
        containsFeature: containsFeature,
        containsFourLanPorts: containsFourLanPorts,
        containsAxSupport: containsAxSupport
    }}>{children}</GlobalsContext.Provider>
}