import { backendStatus } from  '../../backend/backend'

export const deviceHasFourLanPorts = [
    'AX1800',
    'AX1800V'
]

export const LanWlanInterfaceName = {
    lan1: 'LAN_1',
    lan2: 'LAN_2',
    lan3: 'LAN_3',
    lan4: 'LAN_4',
    wlan0: '5G',
    wlan0_vap0: '5G-AP1',
    wlan0_vap1: '5G-AP2',
    wlan0_vap2: '5G-AP3',
    wlan0_vap3: '5G-AP4',
    wlan1: '2.4G',
    wlan1_vap0: '2.4G-AP1',
    wlan1_vap1: '2.4G-AP2',
    wlan1_vap2: '2.4G-AP3',
    wlan1_vap3: '2.4G-AP4'
}

export const Wlan0ID = [
    {
        value: LanWlanInterfaceName.wlan0,
        id: 'wlan0'
    },
    {
        value: LanWlanInterfaceName.wlan0_vap0,
        id: 'wlan0-vap0'
    },
    {
        value: LanWlanInterfaceName.wlan0_vap1,
        id: 'wlan0-vap1'
    },
    {
        value: LanWlanInterfaceName.wlan0_vap2,
        id: 'wlan0-vap2'
    },
    {
        value: LanWlanInterfaceName.wlan0_vap3,
        id: 'wlan0-vap3'
    }
]

export const Wlan1ID = [
    {
        value: LanWlanInterfaceName.wlan1,
        id: 'wlan1'
    },
    {
        value: LanWlanInterfaceName.wlan1_vap0,
        id: 'wlan1-vap0'
    },
    {
        value: LanWlanInterfaceName.wlan1_vap1,
        id: 'wlan1-vap1'
    },
    {
        value: LanWlanInterfaceName.wlan1_vap2,
        id: 'wlan1-vap2'
    },
    {
        value: LanWlanInterfaceName.wlan1_vap3,
        id: 'wlan1-vap3'
    }
]

export async function fetchWanLanList(backend, setWanLanList) {
    
    try{
        let result = await backend.retrieveFresh('wan_lan_list')
        if(result.status === backendStatus.SUCCESS){
            setWanLanList(result.content)
            return result.content
        }
    }catch(error){
        console.error('Error fetching wanLan list')
    }

    return null
}

export async function fetchWanLan(id, backend, setWanLan, containsLanThreeAndFour) {

    let empty = {
        "id": "",
        "lan_list": [
            {"lan_id": "LAN_1", "enabled": false},
            {"lan_id": "LAN_2", "enabled": false},
            {"lan_id": "5G", "enabled": false},
            {"lan_id": "5G-AP1", "enabled": false},
            {"lan_id": "5G-AP2", "enabled": false},
            {"lan_id": "5G-AP3", "enabled": false},
            {"lan_id": "5G-AP4", "enabled": false},
            {"lan_id": "2.4G", "enabled": false},
            {"lan_id": "2.4G-AP1", "enabled": false},
            {"lan_id": "2.4G-AP2", "enabled": false},
            {"lan_id": "2.4G-AP3", "enabled": false},
            {"lan_id": "2.4G-AP4", "enabled": false},
        ]
    }

    if (containsLanThreeAndFour) {
        let hasLan3 = {"lan_id": "LAN_3", "enabled": false}
        let hasLan4 = {"lan_id": "LAN_4", "enabled": false}
        empty.lan_list.push(hasLan3)
        empty.lan_list.push(hasLan4)
    }


    try{
        let result = await backend.retrieveFresh(`wan_lan_list/${id}`)
        if(result.status === backendStatus.SUCCESS && result.content && Object.keys(result.content).length !== 0){
            setWanLan(result.content)
            return result.content
        } else {
            setWanLan(empty)
            return empty
        }
    }catch(error){
        console.error('Error fetching wanLan')
    }

    return null
}

export async function saveWanLan(backend, wl, apply_now = false) {

    try{

        let result = await backend.update(`wan_lan_list/${wl.id}`, wl, '', apply_now)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Wan Lan:', error)

        return false
    }

}