import React, { useState, useEffect, useContext } from 'react'

import Ip, { } from 'ip'

import { fetchAclCapability, saveAclCapability, fetchAclConfigList, saveAclConfig, deleteAclConfigById } from '../../resources/acl-admin';
import { BackendContext } from '../../../backend/backend';
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import Loading from '../../../components/loading/loading';
import Form from '../../../components/form/form';
import { useTranslation } from 'react-i18next'
import Translator from '../../common/components/translator/translator';
import Checkbox from '../../../components/checkbox/checkbox';
import List from '../../../components/list/list';
import InfoIcon from '../../../components/icons/info-icon';
import RemoveIcon from '../../../components/icons/remove';
import Modal, { ModalTypes } from '../../../components/modal/modal';
import errorImg from '../../../images/error.svg';
import common from '../../../components/form/validators/common';
import Input from '../../../components/input/input';
import extraValidators from '../../common/validators';
import Select from '../../../components/select/select';
import Pager from '../../../components/pager/pager';
import { fetchInterfaceLan } from '../../resources/lan';
import { fetchNetworkWanList } from '../../resources/network-wan';
import { Protocol, Mode } from '../network/network-wan/network-wan-common';
import { fixAvailableServiceName } from '../../resources/acl-admin';

const GenericInterface = {
    LAN: 'lan',
    WAN: 'wan'
}

export default function SystemAclAdmin({ isWizard, setWizardAclAdmin, isFormSegment, formSegment }) {

    const MAX_ACL_RULES = 8
    const emptyAdminConfigData = {
        enable: true,
        interfaceID: "lan",
        ip_end_addr: "",
        ip_start_addr: "",
        service_any: false,
        service_tftp: false,
        service_ping: true,
        service_telnet: {
            enable: false,
            port: 23
        },
        service_ftp: {
            enable: false,
            port: 21
        },
        service_http: {
            enable: false,
            port: 80
        },
        service_https: {
            enable: false,
            port: 443
        },
        service_ssh: {
            enable: false,
            port: 22
        }
    }

    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [success, setSuccess] = useState(false)
    let [aclCapability, setAclCapability] = useState(null)
    let [aclConfigList, setAclConfigList] = useState([])
    let [aclConfigItem, setAclConfigItem] = useState({ ...emptyAdminConfigData })
    let [deleteAclAdminConfigItemData, setDeleteAclAdminConfigItemData] = useState(null)
    let [interfaceLan, setInterfaceLan] = useState(null)
    const [wanInterfaces, setWanInterfaces] = useState(null)
    const [pagination, setPagination] = useState(null)

    let [showInfoModal, setShowInfoModal] = useState(false)
    let [servicesAndPorts, setServicesAndPorts] = useState([])
    let [servicesInfo, setServicesInfo] = useState([])

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    const [matches, setMatches] = useState(window.matchMedia("(max-width: 800px)").matches)

    useEffect(() => {
        fetchInterfaceLan(backend, setInterfaceLan)
        fetchNetworkWanList(backend, setWanInterfaces)
        setPagination({ pageNumber: 1, totalElements: 0, pageSize: 5 })
        fetchAclCapability(backend, setAclCapability)
        fetchAclConfigList(backend, setAclConfigList)
        setDeleteAclAdminConfigItemData({ showDelete: false, id: null })

        window
            .matchMedia("(max-width: 800px)")
            .addEventListener('change', e => setMatches(e.matches));

        // eslint-disable-next-line
    }, [])

    let save = async () => {

        let filtered = aclConfigList.filter(acli => acli.interfaceID === GenericInterface.LAN && (acli.service_any || acli.service_http))
        if (aclCapability.acl_capability && filtered.length === 0) {
            setErrorMessage(t('system.acl_admin.message.error.NO_VALID_LAN_RULE'))
            setError(true)
            return
        }

        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        let ok = await saveAclCapability(backend, aclCapability, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    let saveAclConf = async () => {
        let filtered = aclConfigList.filter(acli => acli.id !== aclConfigItem.id && acli.ip_start_addr === aclConfigItem.ip_start_addr && acli.ip_end_addr === aclConfigItem.ip_end_addr && acli.interfaceID === aclConfigItem.interfaceID)
        if (filtered.length > 0) {
            setErrorMessage(t('system.acl_admin.message.error.CONFLIT_WITH_OTHER_RULE'))
            setError(true)
            return
        }
        if (aclConfigItem.service_telnet.port === 0 || aclConfigItem.service_telnet.port === '0' || aclConfigItem.service_telnet.port === null) {
            aclConfigItem.service_telnet.port = emptyAdminConfigData.service_telnet.port
        }
        if (aclConfigItem.service_ftp.port === 0 || aclConfigItem.service_ftp.port === '0' || aclConfigItem.service_ftp.port === null) {
            aclConfigItem.service_ftp.port = emptyAdminConfigData.service_ftp.port
        }
        if (aclConfigItem.service_http.port === 0 || aclConfigItem.service_http.port === '0' || aclConfigItem.service_http.port === null) {
            aclConfigItem.service_http.port = emptyAdminConfigData.service_http.port
        }
        if (aclConfigItem.service_https.port === 0 || aclConfigItem.service_https.port === '0' || aclConfigItem.service_https.port === null) {
            aclConfigItem.service_https.port = emptyAdminConfigData.service_https.port
        }
        if (aclConfigItem.service_ssh.port === 0 || aclConfigItem.service_ssh.port === '0' || aclConfigItem.service_ssh.port === null) {
            aclConfigItem.service_ssh.port = emptyAdminConfigData.service_ssh.port
        }

        var ipMod = Ip


        let gatewayNetAddr = ipMod.mask(interfaceLan.ip4, interfaceLan.netmask)
        let startNetAddr = ipMod.mask(aclConfigItem.ip_start_addr, interfaceLan.netmask)
        let endNetAddr = ipMod.mask(aclConfigItem.ip_end_addr, interfaceLan.netmask)

        if (aclConfigItem.ip_start_addr === interfaceLan.ip4 && aclConfigItem.ip_end_addr === interfaceLan.ip4) {
            setErrorMessage(t('system.acl_admin.message.error.BOTH_IPS_OF_RANGE_CANNOT_BE_EQUALS_THE_DEVICE_IP'))
            setError(true)
            return
        }

        if (aclConfigItem.interfaceID === GenericInterface.LAN &&
            (ipMod.toLong(gatewayNetAddr) !== ipMod.toLong(startNetAddr) || ipMod.toLong(gatewayNetAddr) !== ipMod.toLong(endNetAddr))) {
            setErrorMessage(t('system.acl_admin.message.error.IP_ADDRESSES_ON_RANGE_MUST_BE_IN_THE_SAME_NETWORK_OF_LAN'))
            setError(true)
            return
        }

        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        let ok = await saveAclConfig(backend, aclConfigItem, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        setAclConfigItem({ ...emptyAdminConfigData })
        setAclConfigList([])
        fetchAclConfigList(backend, setAclConfigList)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'there must be a LAN accessible rule to enable ACL')
            return t('system.acl_admin.message.error.LAN_DEFAULT_RULE_MISSING')
        else if (errorMsg === 'No valid lan rule')
            return t('system.acl_admin.message.error.LAN_DEFAULT_RULE_MISSING')
        else
            return errorMsg
    }

    const getInterfaceOptions = () => {
        var interface_list = [{ value: GenericInterface.LAN, text: 'LAN' }, { value: GenericInterface.WAN, text: 'WAN' }]
        for (var i = 0; i < wanInterfaces?.length; i++) {
            const element = wanInterfaces[i];

            if (element.protocol !== Protocol.IPV6 && element.mode !== Mode.BRIDGED)
                interface_list.push(({ value: element.interfaceID, text: element.interfaceID }))
        }

        return interface_list;
    }

    const getColumnsAclConfig = () => {

        let columns = [
            { header: t('system.acl_admin.label.IP_START_ADDR'), align: 'center', size: '150px' },
            { header: t('system.acl_admin.label.IP_END_ADDR'), align: 'center', size: '150px' },
            { header: t('common.label.INTERFACE'), align: 'center', size: '150px' },
            { header: t('system.acl_admin.label.SERVICES'), align: 'center', size: '150px' },
            { header: t('common.label.ACTIONS'), align: 'center', size: '100px' },
        ]

        return columns
    }

    const DeleteAdminConfigItem = (id) => {
        return <Modal show='true'
            title={t('modal.question.WISH_TO_DELETE')}
            type={ModalTypes.CONFIRM}

            onDismissClick={conf => {
                if (conf) {
                    deleteAclAdminConfigItem(id.id)
                }
                setDeleteAclAdminConfigItemData({ showDelete: false })
            }}
            content={
                <div style={{ maxWidth: '520px' }}>
                    <img alt='' src={errorImg} width='50' style={{ padding: '0 0 20px 0' }}></img>
                    <div>
                        {t('common.message.warning.OPERATION_CANT_BE_REVERTED')}<br></br>
                        <b>{t('common.message.warning.WISH_TO_CONTINUE')}</b>
                    </div>
                </div>
            }
            dismissText={t('modal.answer.NO')}
            confirmText={t('modal.answer.YES')}
        ></Modal>
    }

    const deleteAclAdminConfigItem = id => {
        if (aclConfigItem.id === id) {
            if (aclConfigItem.id > 0) {
                setAclConfigItem({ ...emptyAdminConfigData })
            }
        }
        setAclConfigList([])
        deleteAclConfigById(backend, id, setAclConfigList)
    }

    const showAclAdminConfigItem = id => {
        setDeleteAclAdminConfigItemData({ showDelete: true, id: id })
    }

    const isRangeValid = (rangeStart, rangeEnd) => {
        if (!rangeStart || !rangeEnd) return ''
        const [s1, s2, s3, s4] = rangeStart.split('.'),
            [e1, e2, e3, e4] = rangeEnd.split('.')
        return [s1, s2, s3, s4].some(o => parseInt(o) > 255 || parseInt(o) < 0) || [e1, e2, e3, e4].some(o => parseInt(o) > 255 || parseInt(o) < 0) ?
            t('system.acl_admin.message.error.INVALID_IP') :
            parseInt(s1) > parseInt(e1) || parseInt(s2) > parseInt(e2) || parseInt(s3) > parseInt(e3) || parseInt(s4) > parseInt(e4) ?
                t('system.acl_admin.message.error.INVALID_RANGE') :
                ''
    }

    const getAclConfig = () => {

        if (aclConfigList.length !== pagination.totalElements) {
            setPagination({ ...pagination, totalElements: aclConfigList.length })
        }

        let wanLines = [];

        let begining = (pagination.pageNumber - 1) * (pagination.pageSize)
        let end = begining + pagination.pageSize
        if (end > pagination.totalElements) {
            end = pagination.totalElements
        }
        for (let i = begining; i < end; i++) {
            if (aclConfigList[i]) {

                let services = []
                if (aclConfigList[i].service_any) {
                    services.push([t('system.acl_admin.label.ANY'), '--'])
                }
                else {
                    aclCapability.available_services.filter(services => services !== "").forEach(service => {
                        if ((service === 'any' || service === 'tftp' || service === 'ping')) {
                            aclConfigList[i][`service_${service}`] && services.push([service, '--'])
                        }
                        else {
                            aclConfigList[i][`service_${service}`].enable && services.push([service, aclConfigList[i][`service_${service}`].port])
                        }
                    });
                }

                let fields = [
                    aclConfigList[i].ip_start_addr,
                    aclConfigList[i].ip_end_addr,
                    aclConfigList[i].interfaceID,
                    <span className="clickable" onClick={e => {
                        setServicesInfo(
                            <div>
                                {t('common.label.INTERFACE')}: {aclConfigList[i].interfaceID}
                                <br />
                                {t('system.acl_admin.title.IP_RANGE')}: {aclConfigList[i].ip_start_addr} ~ {aclConfigList[i].ip_end_addr}
                            </div>
                        )
                        setServicesAndPorts(services)
                        setShowInfoModal(true)
                    }}><InfoIcon size='20px' /></span>
                ]

                wanLines.push(
                    [...fields,
                    <>
                        {
                            aclConfigList[i].id > 0 ? (
                                <span title={t('common.label.DELETE')} className="firewall-acl-admin-delete-icon clickable" onClick={e => showAclAdminConfigItem(aclConfigList[i].id)}>
                                    <RemoveIcon />
                                </span>
                            ) : '--'
                        }
                    </>
                    ]
                )
            }
        }

        return wanLines

    }

    const systemAclAdminForm = () => {
        return <React.Fragment>
            <Checkbox id='firewall-acl-admin'
                name='acl_capability'
                label={<Translator path="system.acl_admin.label.ENABLE_ACL_ADMIN"></Translator>}
                value={aclCapability.acl_capability}
                toggleFn={(e) => {
                    aclCapability.acl_capability = !aclCapability.acl_capability;
                    setAclCapability({ ...aclCapability })
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    const disablePortFields = (avs) => {
        return (avs === 'any' || avs === 'tftp' || avs === 'ping' || aclConfigItem.interfaceID === 'lan')
    }

    const disableAllServices = () => {
        if (!aclCapability)
            return

        aclCapability.available_services.filter(services => services !== "").forEach((avs, key) => {
            if ((avs === 'any' || avs === 'tftp' || avs === 'ping')) {
                aclConfigItem[`service_${avs}`] = false
            } else {
                aclConfigItem[`service_${avs}`].enable = false
                aclConfigItem[`service_${avs}`].port = emptyAdminConfigData[[`service_${avs}`]].port
            }
        })

        setAclConfigItem({ ...aclConfigItem })
    }

    const systemAclAdminConfigItemForm = () => {

        return <React.Fragment>
            <Input id='firewall-acl-admin-config-ip-start-addr'
                name='ip_start_addr'
                type="text"
                label={<Translator path="system.acl_admin.label.IP_START_ADDR" required="true"></Translator>}
                value={aclConfigItem.ip_start_addr}
                onChange={(e) => {
                    aclConfigItem.ip_start_addr = e.target.value
                    setAclConfigItem({ ...aclConfigItem })
                }}
                validators={[
                    common.required,
                    common.nonASCII,
                    extraValidators.validateIPv4,
                    extraValidators.validateIfNotLocalhost
                ]}
            ></Input>

            <Input id='firewall-acl-admin-config-ip-end-addr'
                name='ip_end_addr'
                type="text"
                label={<Translator path="system.acl_admin.label.IP_END_ADDR" required="true"></Translator>}
                value={aclConfigItem.ip_end_addr}
                onChange={(e) => {
                    aclConfigItem.ip_end_addr = e.target.value
                    setAclConfigItem({ ...aclConfigItem })
                }}
                validators={[
                    common.required,
                    common.nonASCII,
                    extraValidators.validateIPv4,
                    extraValidators.validateIfNotLocalhost
                ]}
                errorMessage={isRangeValid(aclConfigItem.ip_start_addr, aclConfigItem.ip_end_addr)}
            ></Input>

            <Select
                id='firewall-acl-admin-config-interface-id'
                name='interfaceID'
                label={<Translator path="common.label.INTERFACE"></Translator>}
                options={getInterfaceOptions()}
                value={aclConfigItem.interfaceID}
                clearErrors={true}
                onChange={(e) => {
                    aclConfigItem.interfaceID = e.target.value
                    if (aclConfigItem.interfaceID === 'lan') {
                        aclConfigItem.service_ftp.port = emptyAdminConfigData.service_ftp.port
                        aclConfigItem.service_http.port = emptyAdminConfigData.service_http.port
                        aclConfigItem.service_ssh.port = emptyAdminConfigData.service_ssh.port
                        aclConfigItem.service_telnet.port = emptyAdminConfigData.service_telnet.port
                        aclConfigItem.service_https.port = emptyAdminConfigData.service_https.port
                        aclConfigItem.service_ping = true
                    }
                    else {
                        aclConfigItem.service_ping = false
                    }
                    setAclConfigItem({ ...aclConfigItem })
                }}
            ></Select>

            {!aclConfigItem.service_any ?
                <div
                    style={{
                        display: 'grid',
                        width: '100%',
                        gridTemplateColumns: matches ? '100%' : '50%',
                        gridTemplateRows: 'auto',
                    }}
                >
                    {aclCapability.available_services.filter(services => services !== "").map((avs, key) =>
                        <div
                            key={`firewall-acl-admin-config-${avs}`}
                            style={{
                                gridColumnStart: matches ? 1 : parseInt(key % 2) + 1,
                                gridRowStart: matches ? key + 1 : parseInt(key / 2) + 1,
                            }}
                        >
                            <div>
                                <div style={{ display: 'inline-block', width: '26%' }}>
                                    <Checkbox key={avs} id={`firewall-acl-admin-config-${avs}-enable`}
                                        name='avs'
                                        label={<Translator path={`system.acl_admin.label.${avs.toUpperCase()}`}></Translator>}
                                        value={
                                            (avs === 'any' || avs === 'tftp' || avs === 'ping') ? aclConfigItem[`service_${avs}`] : aclConfigItem[`service_${avs}`].enable
                                        }
                                        toggleFn={(e) => {
                                            if ((avs === 'any' || avs === 'tftp' || avs === 'ping')) {
                                                aclConfigItem[`service_${avs}`] = !aclConfigItem[`service_${avs}`];
                                            } else {
                                                aclConfigItem[`service_${avs}`].enable = !aclConfigItem[`service_${avs}`].enable;
                                                if (!aclConfigItem[`service_${avs}`].enable)
                                                    aclConfigItem[`service_${avs}`].port = emptyAdminConfigData[[`service_${avs}`]].port;
                                            }

                                            if (avs === 'any' && aclConfigItem[`service_${avs}`]) {
                                                disableAllServices()
                                                aclConfigItem[`service_${avs}`] = true // Reset service_any value
                                            }

                                            setAclConfigItem({ ...aclConfigItem })
                                        }}
                                        validators={[]}
                                        disabled={(aclConfigItem.interfaceID === 'lan' && avs === 'ping') || (aclConfigItem.interfaceID !== 'lan' && avs === 'any')}
                                    ></Checkbox>
                                </div>
                                <div style={{ display: 'inline-block', width: '74%' }}>
                                    <Input id={`firewall-acl-admin-config-${avs}-port`}
                                        name='port'
                                        label={`${t('advanced.bridging.title.PORT')} ${fixAvailableServiceName(avs)}`}
                                        value={aclConfigItem[`service_${avs}`].port}
                                        placeholder={'--'}
                                        onChange={(e) => {
                                            aclConfigItem[`service_${avs}`].port = (isNaN(Number(e.target.value)) || !e.target.value) ? e.target.value : Number(e.target.value)
                                            setAclConfigItem({ ...aclConfigItem })
                                        }}
                                        validators={[
                                            {
                                                fn: extraValidators.optionalValidators, params: {
                                                    shouldValidate: () => !disablePortFields(avs),
                                                    validators: [
                                                        { fn: common.nonASCII, params: '' },
                                                        { fn: extraValidators.isNumber, params: '' },
                                                        { fn: extraValidators.value, params: { min: 1, max: 65535 } }
                                                    ]
                                                }
                                            },
                                        ]}
                                        disabled={disablePortFields(avs) | !aclConfigItem[`service_${avs}`]?.enable}
                                        dependentValues={[aclConfigItem.interfaceID]}
                                    ></Input>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                :
                <Checkbox id={`firewall-acl-admin-config-any`}
                    name='avs'
                    label={<Translator path={`system.acl_admin.label.ANY`}></Translator>}
                    value={aclConfigItem[`service_any`]}
                    toggleFn={(e) => {
                        let service_any = !aclConfigItem[`service_any`];
                        disableAllServices()
                        aclConfigItem[`service_any`] = service_any;
                        if (!aclConfigItem[`service_any`] && aclConfigItem.interfaceID === 'lan')
                            aclConfigItem.service_ping = true;
                        else
                            aclConfigItem.service_ping = false;

                        setAclConfigItem({ ...aclConfigItem })

                    }}
                    validators={[]}
                ></Checkbox>
            }
        </React.Fragment>
    }

    const changePage = page => {
        setPagination({ ...pagination, pageNumber: page })
    }

    return !aclCapability ? <Loading show={true}></Loading> :
        <div id='acl-admin-page' className='container scroll-area'>

            <div className='subtitle'> <Translator path="menu.ACL_ADMIN"></Translator> </div>

            {deleteAclAdminConfigItemData.showDelete && <DeleteAdminConfigItem id={deleteAclAdminConfigItemData.id}></DeleteAdminConfigItem>}
            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismiss}
                errorText={`${t('system.acl_admin.message.error.ERROR_ON_SERVER')} ${errorMessage ? translateErrorMessagesFromBackend(errorMessage) : ''}`}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <Modal show={showInfoModal}
                title={t('system.acl_admin.title.SERVICE_LIST')}
                type={ModalTypes.INFO}
                onDismissClick={() => setShowInfoModal(false)}
                dismissText={t('common.label.BACK')}
                content={
                    <div >
                        <div>{servicesInfo}</div>
                        <List
                            width='400px'
                            lines={servicesAndPorts}
                            columns={[
                                { header: t('system.acl_admin.label.SERVICES'), align: 'center' },
                                { header: t('advanced.bridging.title.PORT'), align: 'center' }
                            ]}
                        ></List>
                    </div>
                }
            ></Modal>

            <React.Fragment>
                <div className="card mt2">
                    <div className='subtitle'> <Translator path="system.acl_admin.title.ACL_ADMIN_CAPABILITY"></Translator> </div>
                    <Form id='acl-admin-capability-form'
                        onSubmit={save}
                        buttonId='button-save-acl-admin-capability'
                    >
                        {systemAclAdminForm()}
                    </Form>
                </div>
            </React.Fragment>

            <React.Fragment>
                {
                    <div className="card mt2">
                        <div className='subtitle'> <Translator path='system.acl_admin.title.ACL_CONFIG_CREATE'></Translator> </div>
                        <Form id='acl-admin-config-form'
                            onSubmit={saveAclConf}
                            buttonId='button-save-acl-config-admin'
                            disableButton={isRangeValid(aclConfigItem.ip_start_addr, aclConfigItem.ip_end_addr) ||
                                !aclCapability.available_services.filter(services => services !== "").some(avs => {
                                    if ((avs === 'any' || avs === 'tftp' || avs === 'ping')) {
                                        return aclConfigItem[`service_${avs}`]
                                    } else {
                                        return aclConfigItem[`service_${avs}`].enable
                                    }
                                }) || 
                                aclConfigList.length >= MAX_ACL_RULES
                            }
                        >
                            {systemAclAdminConfigItemForm()}
                            {
                               aclConfigList.length >= MAX_ACL_RULES && <strong><Translator path="system.acl_admin.message.error.MAX_RULES_NUM"></Translator></strong>
                            }
                        </Form>
                    </div>
                }
            </React.Fragment>

            <React.Fragment>
                <div className='subtitle acl-admin-config-subtitle'> <Translator path="system.acl_admin.title.ACL_CONFIG_LIST"></Translator> </div>
                <dir className="acl-admin-config-list-wrapper">
                    <List
                        lines={getAclConfig()}
                        columns={getColumnsAclConfig()}
                    ></List>
                </dir>
                <Pager
                    pageNumber={pagination.pageNumber}
                    totalElements={pagination.totalElements}
                    pageSize={pagination.pageSize}
                    pageChangeFn={changePage}>
                </Pager>
            </React.Fragment>

        </div>
}